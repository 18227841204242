<template>
    <a-modal v-model="thisVisible" :width="1100" :bodyStyle="{'height':'500px','overflow':'auto'}" :footer="null" @cancel="onClose()" centered>

        <template v-if="question">
            <template slot="title">
                <div style="padding: 0 20px;height: 47.6px;line-height: 47.6px;border-bottom: 1px solid #e8e8e8;">
                    <i class="iconfont icon-fanhui huiwen-pointer" @click="question = null"></i>
                    <span class="huiwen-margin-left-5">错题详情</span>
                </div>
            </template>
            <div class="huiwen-container huiwen-left ">
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.SINGLE" color="blue" >单选题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.MULTI" color="orange" >多选题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.JUDGE" color="purple" >判断题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.BLANK" color="green" >填空题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.TEXT" color="cyan" >简答题</a-tag>
                <div class="question-title  huiwen-text-bold huiwen-text-small-title" v-html="question.question"></div>

                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <div v-if="question.type == $consts.biz.QUES_TYPES.SINGLE || question.type == $consts.biz.QUES_TYPES.MULTI|| question.type == $consts.biz.QUES_TYPES.JUDGE" class="huiwen-container huiwen-left huiwen-margin-bottom-10" v-for="op in question.options">
                        <template v-if="op.text">
                            <span class="huiwen-text-bold">{{ op.number }}：&nbsp;&nbsp;</span>
                            <span>{{ op.text }}</span>
                        </template>
                    </div>

                    <!--<div class="huiwen-container huiwen-left" style="line-height: 24px">
                        <div class="huiwen-left huiwen-text-bold">选择答案：&nbsp;&nbsp;</div>
                        <div class="huiwen-left">
                            <template v-if="question.type == $consts.biz.QUES_TYPES.SINGLE">
                                <a-radio-group  v-model="question.answerok" disabled>
                                    <a-radio v-for="op2 in question.options" v-if="op2.text" :value="$consts.biz.ANSWER_DICTIONARY[op2.number]">{{ op2.number }}</a-radio>
                                </a-radio-group>
                            </template>
                            <template v-if="question.type == $consts.biz.QUES_TYPES.MULTI" >
                                <a-checkbox-group v-model="question.answerok" disabled>
                                    <a-checkbox v-for="op3 in question.options" v-if="op3.text" :value="$consts.biz.ANSWER_DICTIONARY[op3.number]">{{ op3.number }}</a-checkbox>
                                </a-checkbox-group>

                            </template>
                            <template v-if="question.type == $consts.biz.QUES_TYPES.BLANK">

                            </template>
                        </div>
                        <div class="huiwen-left huiwen-margin-left-10">
                            <a-icon type="close" style="color: #e93d52;"></a-icon>
                        </div>
                    </div>-->
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">正确答案：&nbsp;&nbsp;</div>
                        <div class="huiwen-left huiwen-text-bold huiwen-text-green">{{ question.selectAnswer }}</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">答案解析：&nbsp;&nbsp;</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box huiwen-bg-gray" v-html="question.answer_comments"></div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">我的笔记：&nbsp;&nbsp;</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box huiwen-bg-gray" v-html="question.comments"></div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-20">
                        <a-button block type="danger" @click.stop="toDelete()">移出错题集</a-button>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <template slot="title">
                <div style="padding: 0 20px;height: 47.6px;line-height: 47.6px;border-bottom: 1px solid #e8e8e8;">
                    <span>共收录</span>
                    <span>&nbsp;&nbsp;{{myWrongQuestions.length}}&nbsp;&nbsp;</span>
                    <span>个错题</span>
                </div>
            </template>
            <template v-if="myWrongQuestions.length > 0">
                <a-row :gutter="8">
                    <a-col :span="8" v-for="(item, index) in myWrongQuestions" style="margin-bottom: 8px">
                        <a-card hoverable @click="toWrongQuestionDetail(index)">
                            <div class="huiwen-container huiwen-left huiwen-over-two" style="height: 48px;text-align: left;line-height: 24px;">
                                <span v-if="item.type == $consts.biz.QUES_TYPES.SINGLE" class="ant-tag ant-tag-blue">单选题</span>
                                <span v-if="item.type == $consts.biz.QUES_TYPES.MULTI" class="ant-tag ant-tag-orange">多选题</span>
                                <span v-if="item.type == $consts.biz.QUES_TYPES.JUDGE" class="ant-tag ant-tag-purple">判断题</span>
                                <span v-if="item.type == $consts.biz.QUES_TYPES.BLANK" class="ant-tag ant-tag-green" >填空题</span>
                                <span v-if="item.type == $consts.biz.QUES_TYPES.TEXT" class="ant-tag ant-tag-cyan" >简答题</span>
                                <span class="huiwen-text-bold">{{item.question}}</span>
                            </div>
                            <div class="huiwen-left" style="line-height: 20px;width: 70%">
                                <div class="huiwen-container huiwen-left huiwen-over-one" style="line-height: 20px;">
                                    <span class="huiwen-text-small huiwen-text-gary-light">来自试卷：</span>
                                    <span class="huiwen-text-small huiwen-text-bold huiwen-text-black">{{item.paper_name}}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="line-height: 20px;">
                                    <span class="huiwen-text-small huiwen-text-gary-light">收录日期：</span>
                                    <span class="huiwen-text-small huiwen-text-bold huiwen-text-black">{{item.add_time}}</span>
                                </div>
                            </div>
                            <div class="huiwen-left huiwen-layout-flex-center" style="width: 30%;height: 40px">
                                <a-button size="small" type="danger" @click.stop="toDelete(index)">移出错题集</a-button>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
            </template>
            <template v-else>
                <a-empty style="padding: 100px 0"></a-empty>
            </template>
        </template>
    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"

    export default {
        name: "my-wrong-question",
        props: {
            visible: Boolean
        },
        watch:{
            'visible':function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.queryMyWrongQuestion(1)
                        .then(() => {
                            this.thisVisible = true
                        })
                }
                if(!newVal && oldVal) {
                    this.thisVisible = false
                }
            }
        },
        data() {
            return {
                thisVisible: false,

                pagination: {
                    page: 1,
                    limit: 10,
                    end: false
                },
                myWrongQuestions: [],

                questionIndex: null,
                question: null,
            }
        },
        methods: {
            onClose() {
                const that = this
                that.$emit('close')
            },
            queryMyWrongQuestion(page = this.pagination.page) {
                const that = this
                return new Promise((resolve, reject) => {
                    doQuery({
                        query_code: 'LN020',
                        page: page,
                        limit: that.pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.myWrongQuestions = res.data
                            that.pagination.page = page
                            that.pagination.end = res.data.length < that.pagination.limit
                            resolve()
                        }
                    })
                })
            },
            toWrongQuestionDetail(index) {
                const that = this
                that.questionIndex = index
                doQuery({
                    query_code: 'LN030',
                    param_str2: that.myWrongQuestions[index].question_id,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        var ques = res.data[0]
                        var options = [
                            {
                                number: 'A',
                                text: ques.answer1
                            },
                            {
                                number: 'B',
                                text: ques.answer2
                            },
                            {
                                number: 'C',
                                text: ques.answer3
                            },
                            {
                                number: 'D',
                                text: ques.answer4
                            },
                            {
                                number: 'E',
                                text: ques.answer5
                            },
                            {
                                number: 'F',
                                text: ques.answer6
                            },
                        ]
                        ques.options = options
                        var answerok = ''
                        if(ques.type == that.$consts.biz.QUES_TYPES.SINGLE || ques.type == that.$consts.biz.QUES_TYPES.MULTI || ques.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                            if(ques.answerok) {
                                if(ques.answerok.length == 1) {
                                    answerok = that.$consts.biz.ANSWER_DICTIONARY[ques.answerok]
                                }else if(ques.answerok.length > 1) {
                                    var arr = ques.answerok.split('|')
                                    arr.forEach((a, aIndex) => {
                                        answerok += that.$consts.biz.ANSWER_DICTIONARY[a]
                                        if(aIndex < arr.length - 1) {
                                            answerok = answerok.concat('，')
                                        }
                                    })
                                }
                            }
                        }else if(ques.type == that.$consts.biz.QUES_TYPES.BLANK) {
                            if(ques.answerok) {
                                answerok = ques.answerok.replaceAll('|','、')
                            }
                        }else if(ques.type == that.$consts.biz.QUES_TYPES.TEXT) {
                            if(ques.answerok) {
                                answerok = ques.answerok
                            }
                        }


                        ques.selectAnswer = answerok
                        that.question = ques
                    }
                })
            },
            toDelete(index) {
                const that = this
                if(index != null && index != undefined) {
                    var questionId = that.myWrongQuestions[index].question_id
                }else {
                    var questionId = that.myWrongQuestions[that.questionIndex].question_id
                }

                that.$confirm({
                    centered: true,
                    title: '是否确定将本题从错题本中移除？',
                    onOk() {
                        doAction({
                            action_code: 'LNW1',
                            param_str2: questionId
                        }).then(res => {
                            if(res.errcode >= that.$consts.biz.OK ) {
                                if(res.data.errcode >= that.$consts.biz.OK) {
                                    that.$message.success(that.$consts.biz.OK_MSG)
                                    if(that.questionIndex) {
                                        that.question = null
                                        that.questionIndex = null
                                        var arr = [...that.myWrongQuestions]
                                        arr.splice(that.questionIndex,1)
                                        that.myWrongQuestions = arr
                                    }else {
                                        var arr = [...that.myWrongQuestions]
                                        arr.splice(index,1)
                                        that.myWrongQuestions = arr
                                    }
                                }else {
                                    that.$message.error(that.$consts.biz.FAIL_MSG)
                                }
                            }else {
                                that.$message.error(that.$consts.biz.FAIL_MSG)
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-card-body {
        padding: 10px;
    }
    /deep/ .ant-modal-header {
        padding: 6px 0px;
        border-bottom: none;
    }
    .question-title {
        text-indent: 62px;
        margin-top: -22px;
        line-height: 22px;
    }
</style>

import { doQuery } from '@/api/core'
import consts from '@/constants'
import store from '@/store'

const staff = {
    state: localStorage.getItem(consts.storageKey.STAFF) ? JSON.parse(localStorage.getItem(consts.storageKey.STAFF)) : {
       info: {
           dept_name: '',
           is_admin: '',
           msg_unread: 0,
           need_recommend: '',
           org_name: '',
           org_shortname: '',
           pos_name: '',
           rank: '',
           staff_id: '',
           staff_name: '',
           sum_learning: 0,
           sum_lessons: 0,
           sum_score: 0,
           sum_scoreall:0,
           rank_scoreall:0,
           dept_type:'1'
       }
    },

    mutations: {
        SET_MSG_UNREAD: (state, msg_unread) => {
            state.info.msg_unread = msg_unread
            localStorage.setItem(consts.storageKey.STAFF,JSON.stringify(state))
        },
        SET_STAFF_INFO: (state, info) => {
            state.info = info
            localStorage.setItem(consts.storageKey.STAFF,JSON.stringify(state))
        },
        SAVE_STAFF_LOCAL_STORAGE:(state) => {
            localStorage.setItem(consts.storageKey.STAFF,JSON.stringify(state))
        }
    },

    actions: {
        // 获取员工信息
        GetStaffInfo ({ commit }) {
            return new Promise((resolve, reject) => {
                doQuery({
                    query_code: 'LN000',
                    param_str2: store.getters.mobilephone
                }).then(res => {
                    if(res.errcode === consts.biz.OK) {
                        commit('SET_STAFF_INFO',res.data[0])
                        commit('SAVE_STAFF_LOCAL_STORAGE')
                        resolve(res)
                    }else {
                        reject(res)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

    }
}

export default staff

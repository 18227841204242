import nsocket from "./socket/noticesocket";

const getters = {
    winSize: state => state.app.winSize,

    token: state => state.user.token,

    myInfo: state => state.user.info,
    mobilephone: state => state.user.info.mobilephone,

    staffId: state => state.staff.info.staff_id,
    isAdmin: state => state.staff.info.is_admin,
    myStaffInfo: state => state.staff.info,

    searchText: state => state.lesson.searchText,
    trainingId: state => state.lesson.trainingId,
    trainingVisible: state => state.lesson.trainingVisible,
    refreshTrainingTaskItem: state => state.lesson.refreshTrainingTaskItem,

    seriesId: state => state.lesson.seriesId,
    seriesVisible: state => state.lesson.seriesVisible,
    refreshSeriesTaskItem: state => state.lesson.refreshSeriesTaskItem,

    getPkdbtypes: state => state.kdbtype.pkdbtypes ? state.kdbtype.pkdbtypes : [],
    getKdbtypes: state => state.kdbtype.kdbtypes ? state.kdbtype.kdbtypes : [],

    qToken: state => state.user.qToken,

    nws: state => state.nsocket.nws,
    boNwsValid: state => state.nsocket.nws && state.nsocket.nws.readyState == 1,

    qws: state => state.qsocket.qws,
    boQwsValid: state => state.qsocket.qws && state.qsocket.qws.readyState == 1
}

export default getters

<template>
    <a-modal v-model="thisVisible" :title="'共参与 '+ myExaminations.length +' 个问卷和考试'" :width="1100" :bodyStyle="{'height':'500px','overflow':'auto'}" :footer="null" @cancel="onClose()" centered>
        <template v-if="myExaminations.length > 0">
            <a-row :gutter="8">
                <a-col :span="8" v-for="(item, index) in myExaminations" style="margin-bottom: 8px">
                    <a-card hoverable @click="toTaskDetail(index)">
                        <div class="huiwen-container huiwen-left huiwen-over-two" style="height: 48px;text-align: left;line-height: 24px;">
                            <span v-if="item.paper_type == '1'" class="ant-tag ant-tag-purple">调查</span>
                            <span v-if="item.paper_type == '0'" class="ant-tag ant-tag-red">考试</span>
                            <span class="huiwen-text-bold">{{item.name}}</span>
                        </div>
                        <div class="huiwen-container huiwen-left" style="line-height: 20px;">
                            <span class="huiwen-text-small huiwen-text-gary-light">来自任务：</span>
                            <span v-if="item.allow_delay == '1' && item.deadmins<0" class="huiwen-text-small huiwen-text-orange">&nbsp;&nbsp;已超时&nbsp;</span>
                            <span v-if="item.allow_delay == '0' && item.deadmins<0" class="huiwen-text-small huiwen-text-red">&nbsp;&nbsp;已截止&nbsp;&nbsp;</span>
                            <span class="huiwen-text-small huiwen-text-bold huiwen-text-black">{{item.task_name}}</span>
                        </div>
                        <div class="huiwen-container huiwen-left" style="line-height: 20px;">
                            <span class="huiwen-text-small huiwen-text-gary-light">{{item.dispatch_staff_name}}于 {{item.dispatch_time}} 指派</span>
                            <span v-if="item.paper_type == '1' && item.status == '0'" class="huiwen-text-small huiwen-text-red huiwen-right">未完成</span>
                            <span v-if="item.paper_type == '0' && item.status == '9'" class="huiwen-text-small huiwen-text-red huiwen-right">未通过</span>
                            <span v-if="item.paper_type == '0' && item.status == '0'" class="huiwen-text-small huiwen-text-red huiwen-right">未完成</span>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </template>
        <template v-else>
            <a-empty style="padding: 100px 0"></a-empty>
        </template>

    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"
    import dateUtils from '@/utils/dateUtils'

    export default {
        name: "my-examination",
        props: {
            visible: Boolean
        },
        watch:{
            'visible':function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.refreshMyExamination()
                        .then(() => {
                            this.thisVisible = true
                        })
                }
                if(!newVal && oldVal) {
                    this.thisVisible = false
                }
            }
        },
        data() {
            return {
                thisVisible: false,
                myExaminations: [],
                pagination: {
                    page: 1,
                    limit: 10,
                    end: false
                },
            }
        },
        methods: {
            onClose() {
                const that = this
                that.$emit('close')
            },
            refreshMyExamination(page = this.pagination.page) {
                const that = this
                return new Promise((resolve, reject) => {
                    doQuery({
                        query_code: 'LNF10',
                        page: page,
                        limit: that.pagination.limit,
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.myExaminations = res.data
                            that.pagination.page = page
                            that.pagination.end = res.data.length < that.pagination.limit
                            resolve()
                        }
                    })
                })
            },
            toTaskDetail(index) {
                const that = this
                var task = that.myExaminations[index]

                //如果超时，而且不允许超时完成，则禁止
                if (that.myExaminations[index].allow_delay == '0' &&
                    that.myExaminations[index].deadline_time < dateUtils.getNow()) {

                    that.$message.error('该任务已截止')
                    return;

                }

                if(task.paper_type == '1') {
                    that.$router.push({path: '/student/questionaire?taskId=' + task.task_id })
                }else if(task.paper_type == '0') {
                    that.$router.push({path: '/student/examination?taskId=' + task.task_id + '&taskItemId=' + task.taskitem_id})
                }
                that.onClose()
            },
        }
    }
</script>

<style scoped>
    /deep/ .ant-card-body {
        padding: 10px;
    }
</style>

import consts from '@/constants'
import store from '@/store'

const app = {
    state: {
        winSize: [document.documentElement.clientWidth, document.documentElement.clientHeight]
    },
    mutations: {
        SET_WIN_SIZE: (state, winSize) => {
            state.winSize = winSize
        },
    },

    actions: {

    }
}

export default app

import { BasicLayout, StudentLayout } from '@/layouts'
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routers = [
    {
        path: '/',
        component: BasicLayout,
        redirect: '/index',
        meta: { title: '首页' },
        children: [
            {
                path: 'index',
                name: 'Index',
                component: () => import('@/views/index/index.vue'),
                meta: { title: '首页' }
            },{
                path: 'about-us',
                name: 'AboutUs',
                component: () => import('@/views/aboutUs/aboutUs.vue'),
                meta: { title: '关于我们' }
            },{
                path: 'training-service',
                name: 'TrainingService',
                component: () => import('@/views/trainingService/trainingService.vue'),
                meta: { title: '解决方案' }
            },{
                path: 'contact-us',
                name: 'ContactUs',
                component: () => import('@/views/contactUs/contactUs.vue'),
                meta: { title: '联系我们' }
            }
        ]
    },{
        path: '/student/login',
        name: 'StudentLogin',
        component: () => import('@/views/student/login/login.vue'),
        meta: { title: '学生端-登录' },
    },{
        path: '/article',
        name: 'Article',
        component: () => import('@/views/article/article.vue'),
        meta: { title: '文章阅读' },
    },{
        path: '/student',
        name: 'Student',
        component: StudentLayout,
        redirect: '/student/index',
        meta: { title: '学生端-首页' },
        children: [
            {
                path: 'index',
                name: 'StudentIndex',
                component: () => import('@/views/student/index/index.vue'),
                meta: { title: '学生端-首页' }
            },{
                path: 'task',
                name: 'StudentTask',
                component: () => import('@/views/student/task/task.vue'),
                meta: { title: '学生端-任务' }
            },{
                path: 'lesson',
                name: 'StudentLesson',
                component: () => import('@/views/student/lesson/lesson.vue'),
                meta: { title: '学生端-课程' }
            },{
                path: 'knowledge',
                name: 'StudentKnowledge',
                component: () => import('@/views/student/knowledge/knowledge.vue'),
                meta: { title: '学生端-知识库' }
            },{
                path: 'searchRes',
                name: 'StudentSearchRes',
                component: () => import('@/views/student/searchRes/searchRes.vue'),
                meta: { title: '学生端-搜索结果' }
            },{
                path: 'singleLesson',
                name: 'StudentSingleLesson',
                component: () => import('@/views/student/singleLesson/singleLesson.vue'),
                meta: { title: '学生端-单次课程' }
            }, {
                path: 'questionaire',
                name: 'StudentQuestionaire',
                component: () => import('@/views/student/questionaire/questionaire.vue'),
                meta: { title: '学生端-调查问卷' }
            }, {
                path: 'examination',
                name: 'StudentExamination',
                component: () => import('@/views/student/examination/examination.vue'),
                meta: { title: '学生端-考试' }
            },{
                path: 'tools',
                name: 'StudentTools',
                component: () => import('@/views/student/tools/tools.vue'),
                meta: { title: '工具箱' }
            },{
                path: 'distributeKnowledge',
                name: 'StudentDistributeKnowledge',
                component: () => import('@/views/student/distributeKnowledge/distributeKnowledge.vue'),
                meta: { title: '贡献知识' }
            },{
                path: 'brushQuestion',
                name: 'StudentBrushQuestion',
                component: () => import('@/views/student/brushQuestion/brushQuestion.vue'),
                meta: { title: '智能刷题训练' }
            },{
                path: 'textPractice',
                name: 'StudentTextPractice',
                component: () => import('@/views/student/textPractice/textPractice.vue'),
                meta: { title: '智能对话训练' }
            },{
                path: 'bbs',
                name: 'StudentBBS',
                component: () => import('@/views/student/bbs/bbs.vue'),
                meta: { title: '学员空间' }
            },{
                path: 'postDetail',
                name: 'StudentPostDetail',
                component: () => import('@/views/student/postDetail/postDetail.vue'),
                meta: { title: '帖子详情' }
            },{
                path: 'taxGuide',
                name: 'StudentTaxGuide',
                component: () => import('@/views/student/taxGuide/taxGuide.vue'),
                meta: { title: '办税指南' }
            },{
                path: 'consult/robot',
                name: 'StudentAIConsult',
                component: () => import('@/views/student/robotConsult/robotConsult.vue'),
                meta: { title: '智能AI咨询' }
            },{
                path: 'question/publish',
                name: 'StudentPublishQuestion',
                component: () => import('@/views/student/publishQues/publishQues.vue'),
                meta: { title: '财税辅导' }
            },{
                path: 'consult/manual',
                name: 'StudentManualConsult',
                component: () => import('@/views/student/manualConsult/manualConsult.vue'),
                meta: { title: '专家辅导' }
            }
        ]
    }
]

export default new Router({
    // mode: 'history', //后端支持可开
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        }
        return {x: 0, y: 0}
    },
    routes: routers
})

<template>
    <a-drawer
            width="400"
            placement="right"
            @close="onClose"
            :closable="false"
            :visible="trainingId && trainingVisible"
    >
        <div class="huiwen-container huiwne-left" v-if="task">
            <img :src="task.task_img && task.task_img != '' ? task.task_img : imgSrcList[1]" class="huiwen-container huiwen-left">
            <div v-if="!task.task_img || task.task_img==''" class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box" style="line-height: 24px">
                <a-tag color="blue">训练营</a-tag> <span>{{task.task_name}}</span>
            </div>
            <div class="huiwen-container huiwen-left  huiwen-border-box" style="padding: 0 10px">
                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <span class="huiwen-text-bold">任务时间：</span>
                    <span>{{  task.start_time  }} ~ {{ task.deadline_time }}</span>
                </div>
                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <span class="huiwen-text-bold">任务状态：</span>
                    <template v-if="task.deadmins > 0">
                        <span>还有</span>
                        <span class="huiwen-text-red huiwen-margin-left-right-5">{{ task.deadminsFormat }}</span>
                        <span>截止</span>
                    </template>
                    <template v-else>
                        <span class="huiwen-text-red">已截止</span>
                    </template>
                </div>
                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <div class="huiwen-left" style="width: 50%">
                        <span class="huiwen-text-bold">任务课时：</span>
                        <span>{{ taskitems ? taskitems.length : 0 }}</span>
                    </div>
                    <div class="huiwen-left" style="width: 50%">
                        <span class="huiwen-text-bold">参与人数：</span>
                        <span>{{ task.staff_cnt }}</span>
                    </div>
                </div>
                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <div class="huiwen-left" style="width: 100%">
                        <span class="huiwen-text-bold">任务指派：</span>
                        <span>{{ task.dispatch_staff }} 于 {{ task.dispatch_time }} 指派</span>
                    </div>
                </div>
            </div>


            <a-alert v-if="task.is_started == '0'" class="huiwen-container huiwen-left" style="margin-top: 10px"  message="训练营尚未开始，请耐心等候..." type="error" banner  />
            <div v-else class="huiwen-container huiwen-left huiwen-margin-top-10 huiwen-border-bottom-gray"></div>
            <div class="huiwen-container huiwen-left" >
                <template v-if="taskitems && taskitems.length > 0">
                    <div v-for="(taskitem,idx) in taskitems" class="huiwen-container huiwen-left  huiwen-border-box huiwen-pointer"
                         :style="{'padding': '10px','background-color':taskitem.is_enabled=='0' ? '#f5f5f6': ''}"
                         @click="toDoTheTaskItem(taskitem,idx)">
                        <div class="huiwen-container huiwen-left huiwen-text-bold huiwen-bg-blue huiwen-text-white huiwen-padding-all-5 huiwen-border-box"
                             v-if="taskitem.item_segment && taskitem.item_segment != '' && (idx == 0 || taskitem.item_segment != taskitems[idx-1].item_segment)">
                            {{taskitem.item_segment}}
                        </div>
                        <div v-if="taskitem.unlock_starttime_day != ''" class="huiwen-container huiwen-left huiwen-text-bold huiwen-text-common huiwen-margin-top-5">
                            <span>{{taskitem.unlock_starttime_day}}</span>
                            <span class="huiwen-margin-left-right-5">至</span>
                            <span>{{taskitem.unlock_endtime_day != '' ? taskitem.unlock_endtime_day:'训练营结束'}}</span>
                            <span v-if="taskitem.is_enabled == '0'"  class="iconfont icon-suoding" style="font-size:13px;color:gray;">未解锁</span>

                            <span v-if="$route.query.taskItemId == taskitem.taskitem_id" class="huiwen-right huiwen-text-green">进行中</span>
                        </div>
                        <div v-else-if="taskitem.unlock_type != '0'" class="huiwen-container huiwen-left huiwen-text-bold huiwen-text-common huiwen-margin-top-5">
                            <span>不限时</span>
                        </div>


                        <!--显示任务类型、是否等待测试、任务标题-->
                        <div class="huiwen-container huiwen-left huiwen-over-one huiwen-margin-top-10">
                            <span :class="'iconfont ' + taskitem.taskitemicon" style="color:#f56400;margin-right:5px;"></span>
                            <span class="ant-tag ant-tag-orange" v-if="taskitem.percent_done >=100 && taskitem.is_passed != '1'">待考试</span>
                            <span class="huiwen-text-blue">{{idx+1}}. {{taskitem.item_title}}</span>
                        </div>

                        <div class="huiwen-container huiwen-left">
                            <a-progress :percent="taskitem.percent_done" />
                        </div>

                    </div>
                </template>
                <template v-else>
                    <a-empty  style="padding: 100px 0"/>
                </template>

            </div>

        </div>
        <div v-show="trainingId" class="setting-drawer-index-handle" @click="toggle" slot="handle">
            <i class="iconfont icon-xunlianying" v-if="!trainingVisible"></i>
            <a-icon type="close" v-else/>
        </div>
    </a-drawer>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    import { doQuery } from "@/api/core"
    import consts from '@/constants'
    import dateUtils from '@/utils/dateUtils'
    import util from '@/utils/cmyUtils'

    export default {
        name: "training-task-drawer",
        inject: ['reload'],
        data() {
            return {

                task: null,
                taskitems: null,

                imgSrcList: [
                    consts.oss.BASE_PATH_MP + 'lessons/microclass-2.png'
                ],
            }
        },
        computed: {
            ...mapGetters([
                'trainingId',
                'trainingVisible',
                'refreshTrainingTaskItem'
            ])
        },
        watch: {
            'trainingVisible': function(newVal, oldVal) {
                if(newVal && !oldVal) {
                    this.refreshTask(() => {
                        this.refreshTaskItems()
                    })
                }
            },
            'trainingId': function(newVal, oldVal) {
                if(newVal && !oldVal) {
                    this.refreshTask(() => {
                        this.refreshTaskItems(() => {
                            this.SET_TRAINING_VISIBLE(true)
                        })
                    })
                }else if(!newVal && oldVal) {
                    this.SET_TRAINING_VISIBLE(false)
                }else if(newVal && oldVal) {
                    this.refreshTask(() => {
                        this.refreshTaskItems(() => {
                            this.SET_TRAINING_VISIBLE(true)
                        })
                    })
                }
            },
            'refreshTrainingTaskItem': function(newVal, oldVal) {
                this.refreshTaskItems()
            },
            // 进入训练营任务后，如果出了训练营的相关菜单 则取消训练营drawer
            '$route.name': function(newVal, oldVal) {
                var arr = ['StudentSingleLesson','StudentQuestionaire','StudentExamination']

                if(oldVal == 'StudentTask' && !arr.includes(newVal)) {
                    this.SET_TRAINING_ID(null)
                }
                if(oldVal == 'StudentIndex' && !arr.includes(newVal)) {
                    this.SET_TRAINING_ID(null)
                }
                if(arr.includes(oldVal) && !arr.includes(newVal)) {
                    this.SET_TRAINING_ID(null)
                }
            }
        },
        mounted() {

        },
        methods: {
            ...mapMutations([
                'SET_TRAINING_ID',
                'SET_TRAINING_VISIBLE'
            ]),
            toggle() {
                const that = this
                that.SET_TRAINING_VISIBLE(!that.trainingVisible)
            },
            onClose() {
                const that = this
                that.SET_TRAINING_VISIBLE(false)
            },
            refreshTask(func) {
                const that = this
                doQuery({
                    query_code: 'LN100' ,
                    param_str1: '0',
                    param_str3: that.trainingId,
                    page: 1,
                    limit: 1,
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK) {
                        that.task = res.data[0]
                        that.task.deadminsFormat = util.formatMinutesEx(that.task.deadmins);

                        if(func) {
                            func()
                        }
                    }else {
                        that.task = null
                    }
                })
            },

            refreshTaskItems(func) {
                const that = this
                doQuery({
                    query_code: 'LN110',
                    param_str2: that.trainingId,
                    page: 1,
                    limit: 100
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        res.data.forEach(item => {
                            item.taskitemicon = 'icon-'.concat(item.taskitemicon.substring(4))
                        })
                        that.taskitems = res.data
                    }else {
                        that.taskitems = null
                    }
                    if(func) {
                        func()
                    }
                })
            },
            hasPassedPreLessons(current) {
                const that = this
                var boPassed = true;

                for (let i=0;i<current;i++){
                    if (that.taskitems[i].is_passed == '0'){
                        boPassed = false;
                        break;
                    }
                }

                return boPassed;
            },
            toDoTheTaskItem(taskitem,current) {
                const that = this
                if (taskitem.is_enabled == '0') {
                    that.$message.error('本学习单元尚未解锁')
                }else if((taskitem.unlock_type == '3' || taskitem.unlock_type == '1') && !that.hasPassedPreLessons(current)) {
                    that.$message.error('请首先完成前置学习任务')
                }else if(that.task.tasktype_code == that.$consts.biz.TASK_TYPES.COMP || that.task.tasktype_code == that.$consts.biz.TASK_TYPES.TRAIN) {
                    //问卷调查
                    if (taskitem.itemtype_code == '10') {
                        that.$router.replace({path: '/student/questionaire?taskId=' + that.task.task_id + '&taskItemId=' + taskitem.taskitem_id})
                    }

                    //直接考试
                    if (taskitem.itemtype_code == '20') {
                        that.$router.replace({path: '/student/examination?taskId=' + that.task.task_id + '&taskItemId=' + taskitem.taskitem_id})
                    }

                    //单次课程培训
                    if (taskitem.itemtype_code == '30' || taskitem.itemtype_code == '31' || taskitem.itemtype_code == '32') {
                        that.$router.replace({path: '/student/singleLesson?taskId=' + that.task.task_id + '&taskItemId=' + taskitem.taskitem_id})
                    }
                    that.SET_TRAINING_VISIBLE(false)
                }
            }
        }
    }
</script>

<style scoped>

    /deep/ .ant-drawer-body {
        padding: 0;
        float: left;
        width: 100%;
    }

    .setting-drawer-index-handle {
        position: fixed;
        top: 300px;
        background: #1890ff;
        width: 48px;
        height: 48px;
        right: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: auto;
        z-index: 8;
        text-align: center;
        font-size: 16px;
        border-radius: 4px 0 0 4px;
    }
    .setting-drawer-index-handle i {
        color: rgb(255, 255, 255);
        font-size: 20px;
    }
</style>

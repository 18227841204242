<template>
    <a-modal v-model="thisVisible" :title="'共收藏 '+ myFavoriteLessons.length +' 个课程'" :width="1100" :bodyStyle="{'height':'500px','overflow':'auto'}" :footer="null" @cancel="onClose()" centered>
        <template v-if="myFavoriteLessons.length > 0">
            <a-row :gutter="8">
                <a-col :span="8" v-for="(item, index) in myFavoriteLessons" style="margin-bottom: 8px">
                    <a-card hoverable @click="toLessonDetail(item.lesson_id)">
                        <img :src="item.title_img" class="huiwen-left" style="width: 40%;height: 83px;width: 137px"></img>
                        <div class="huiwen-left huiwen-padding-left-right-5 huiwen-border-box" style="width: calc(100% - 137px)">
                            <div class="huiwen-container huiwen-left huiwen-text-common huiwen-text-bold huiwen-over-two" style="height: 43px;line-height: 21.5px">
                                {{ item.title }}
                            </div>
                            <div class="huiwen-left" style="height: 40px;width: 60%">
                                <div class="huiwen-container huiwen-left huiwen-text-small huiwen-text-gary-light" style="height: 20px">
                                    {{item.kdbtype?item.kdbtype:'未知类型'}}
                                </div>
                                <div class="huiwen-container huiwen-left huiwen-text-small huiwen-text-gary-light" style="height: 20px">
                                    收藏于 {{String(item.favorate_time).substr(0,10)}}
                                </div>
                            </div>
                            <div class="huiwen-left huiwen-layout-flex-center" style="height: 40px;width: 40%">
                                <a-button size="small" type="danger" @click.stop="cancelFavorite(item.lesson_id)">取消收藏</a-button>
                            </div>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </template>
        <template v-else>
            <a-empty style="padding:100px 0"></a-empty>
        </template>

    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core";

    export default {
        name: "my-favorite-lesson",
        props: {
            visible: Boolean
        },
        watch:{
            'visible':function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.refreshMyFavoriteLesson(1)
                        .then(() => {
                            this.thisVisible = true
                        })
                }
                if(!newVal && oldVal) {
                    this.thisVisible = false
                }
            }
        },
        data() {
            return {
                thisVisible: false,

                pagination: {
                    page: 1,
                    limit: 10,
                    end: false
                },

                myFavoriteLessons: []
            }
        },
        methods: {
            onClose() {
                const that = this
                that.$emit('close')
            },
            refreshMyFavoriteLesson(page =  this.pagination.page) {
                const that = this
                return new Promise((resolve, reject) => {
                    doQuery({
                        query_code: 'LNF00',
                        page: page,
                        limit: that.pagination.limit,
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.myFavoriteLessons = res.data
                            that.pagination.page = page
                            that.pagination.end = res.data.length < that.pagination.limit
                            resolve()
                        }
                    })
                })
            },
            toLessonDetail(lessonId) {
                const that = this
                that.$router.push({path: '/student/singleLesson?lessonId=' + lessonId})
                that.onClose()
            },
            cancelFavorite(lessonId) {
                const that = this
                doAction({
                    action_code: 'LN11',
                    param_str2: lessonId
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK ) {
                        if(res.data.errcode >= that.$consts.biz.OK) {
                            that.$message.success(that.$consts.biz.OK_MSG)
                            that.refreshMyFavoriteLesson(1)
                        }else {
                            that.$message.error(that.$consts.biz.FAIL_MSG)
                        }
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-card-body {
        padding: 0;
        height: 83px;
    }
</style>

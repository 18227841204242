<template>
    <a-drawer
            width="400"
            placement="right"
            @close="onClose"
            :closable="false"
            :visible="seriesId && seriesVisible"
    >
        <div class="huiwen-container huiwne-left" v-if="series_detail">
            <img :src="series_detail.series_img && series_detail.series_img != '' ? series_detail.series_img : imgSrcList[1]" class="huiwen-container huiwen-left" />
            <div v-if="!series_detail.series_img || series_detail.series_img==''" class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box" style="line-height: 24px">
                <a-tag color="cyan">财税系列微课</a-tag> <span>{{ series_detail.series_name }}</span>
            </div>
            <a-alert type="info" banner :show-icon="false" class="huiwen-container huiwen-left huiwen-text-center">
                <template slot="message">

                    <span class="huiwen-text-blue huiwen-pointer" v-if="series_detail.paper_id==null || series_detail.paper_id==''">点此确认完成观看</span>
                    <span class="huiwen-text-blue huiwen-pointer" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == ''" @click="onClickConfirm()">已全部学习完成，前往考试</span>
                    <span class="huiwen-text-blue huiwen-pointer" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '0'"  @click="onClickConfirm()">正在课程考试，点此继续</span>
                    <span class="huiwen-text-blue huiwen-pointer" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '1'" @click="onClickConfirm()">已通过课程考试，点此回顾</span>
                    <span class="huiwen-text-blue huiwen-pointer" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '9'" @click="onClickConfirm()">未通过课程考试，点此重考</span>

                    <span class="huiwen-text-gary-light" style="margin-left: 8px" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == ''">【未开始】</span>
                    <span class="huiwen-text-blue" style="margin-left: 8px" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '0'" color="blue">【进行中】</span>
                    <span class="huiwen-text-green" style="margin-left: 8px" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '1'" color="green">【已通过】</span>
                    <span class="huiwen-text-red" style="margin-left: 8px" v-if="series_detail.paper_id && series_detail.paper_id!= '' && series_detail.is_passed == '9'" color="gray">【未通过】</span>
                </template>
            </a-alert>

            <div class="huiwen-container huiwen-left  huiwen-layout-flex-center huiwen-margin-top-10" >
                <a-button-group>
                    <a-button :type="is_catelog == 1 ? 'primary' : 'default'" @click="is_catelog = 1">课程说明</a-button>
                    <a-button :type="is_catelog == 2 ? 'primary' : 'default'" @click="is_catelog = 2">课程列表</a-button>
                </a-button-group>
            </div>

            <div v-if="is_catelog == 1" class="huiwen-container huiwen-left intro" v-html="article_contenttext"></div>
            <div v-if="is_catelog == 2" class="huiwen-container huiwen-left huiwen-border-box" style="padding: 10px">
                <div v-for="(item, index) in videoList" class="huiwen-container huiwen-left huiwen-border-bottom-gray-light huiwen-margin-bottom-10 huiwen-pointer" @click="onClickLesson(item.lesson_id)">
                    <div class="huiwen-left huiwen-text-bold huiwen-text-small-title" style="width: 80%">
                        <span>{{ index + 1 }}、</span>
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="huiwen-left huiwen-text-right" style="width: 20%">
                        <span v-if="$route.query.lessonId == item.lesson_id" class="huiwen-text-bold huiwen-text-green huiwen-text-common">进行中</span>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-bottom-10">
                        <a-progress v-if="item.video_length > 0" :percent="Math.floor((item.lesson_progress*100)/item.video_length)" />
                        <span v-else class="huiwen-text-gary-light huiwen-text-small">视频长度未知</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="seriesId" class="setting-drawer-index-handle" @click="toggle" slot="handle">
            <i class="iconfont icon-xiliekebiaoqian" v-if="!seriesVisible"></i>
            <a-icon type="close" v-else/>
        </div>
    </a-drawer>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    import { doQuery } from "@/api/core"
    import consts from '@/constants'

    export default {
        name: "series-task-drawer",
        computed: {
            ...mapGetters([
                'seriesId',
                'seriesVisible',
                'refreshSeriesTaskItem'
            ])
        },
        watch: {
            'seriesId': function(newVal, oldVal) {
                if(newVal && !oldVal) {
                    this.refreshSeriesDetail(() => {
                        this.SET_SERIES_VISIBLE(true)
                    })
                }else if(!newVal && oldVal) {
                    this.SET_SERIES_VISIBLE(false)
                }else if(newVal && oldVal) {
                    this.refreshSeriesDetail(() => {
                        this.SET_SERIES_VISIBLE(true)
                    })
                }
            },
            // 进入训练营任务后，如果出了训练营的相关菜单 则取消训练营drawer
            '$route.name': function(newVal, oldVal) {
                var arr = ['StudentSingleLesson','StudentQuestionaire','StudentExamination']

                if(oldVal == 'StudentTask' && !arr.includes(newVal)) {
                    this.SET_SERIES_ID(null)
                }
                if(oldVal == 'StudentIndex' && !arr.includes(newVal)) {
                    this.SET_SERIES_ID(null)
                }
                if(arr.includes(oldVal) && !arr.includes(newVal)) {
                    this.SET_SERIES_ID(null)
                }
            }
        },
        data() {
            return {
                /*系列课程列表*/
                series_detail: null,
                videoList: [],
                article_contenttext: '',

                is_catelog: 2
            }
        },
        methods: {
            ...mapMutations([
                'SET_SERIES_ID',
                'SET_SERIES_VISIBLE'
            ]),
            toggle() {
                const that = this
                that.SET_SERIES_VISIBLE(!that.seriesVisible)
            },
            onClose() {
                const that = this
                that.SET_SERIES_VISIBLE(false)
            },
            refreshSeriesDetail(func) {
                const that = this
                doQuery({
                    query_code: 'LNS0',
                    param_str3: that.seriesId,
                    page: 1,
                    limit: 10
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.series_detail = res.data[0]
                        setTimeout(() => {
                            that.article_contenttext = res.data[0].series_intro ? res.data[0].series_intro : ''
                        }, 200)
                        //刷新视频列表信息
                        that.refreshVideoList()
                        if(func) {
                            func()
                        }
                    }else {
                        that.series_detail = null
                    }
                })
            },
            refreshVideoList() {
                const that = this
                doQuery({
                    query_code: 'LNS1',
                    param_str2: that.series_detail.series_id,
                    page: 1,
                    limit: 10
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        res.data.forEach((item, index) => {
                            item.create_time = item.create_time.substring(0, 10)
                        })
                        that.videoList = res.data
                    }else {
                        that.videoList = []
                    }
                })
            },
            onClickConfirm() {
                const that = this
                //如果需要考试
                if (that.series_detail.paper_id && that.series_detail.paper_id != '') {
                    that.$router.replace({path: '/student/examination?taskId=' + that.seriesId + '&taskItemId=' + that.series_detail.taskitem_id})
                    that.SET_SERIES_VISIBLE(null)
                }
            },
            onClickLesson(lessonId) {
                const that = this
                that.$router.replace({path: '/student/singleLesson?lessonId=' + lessonId + '&taskId=' + that.seriesId})
                that.SET_SERIES_VISIBLE(null)
            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-drawer-body {
        padding: 0;
        float: left;
        width: 100%;
    }

    .setting-drawer-index-handle {
        position: fixed;
        top: 300px;
        background: #1890ff;
        width: 48px;
        height: 48px;
        right: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: auto;
        z-index: 8;
        text-align: center;
        font-size: 16px;
        border-radius: 4px 0 0 4px;
    }
    .setting-drawer-index-handle i {
        color: rgb(255, 255, 255);
        font-size: 20px;
    }

    /deep/ .intro {
        padding: 10px;
        box-sizing: border-box;
    }

    /deep/ .intro img {
        width: 100%;
    }
</style>

import { loginRequest, learningRequest } from '@/utils/request'
import consts from '@/constants'
import md5 from 'md5'

export function login ({ username, password }) {
    return loginRequest({
        url: consts.url.LOGIN,
        //baseURL: '/dev-api',
        method: 'post',
        data: {
            username,
            password: password ? md5(password) : ''
        }
    })
}

export function getInfo () {
    return learningRequest({
        url: consts.url.GET_USER_INFO,
        //baseURL: '/dev-api',
        method: 'get',
    })
}

export function resetPassword (parameter) {
    return learningRequest({
        url: consts.url.RESER_PASSWORD,
        //baseURL: '/dev-api',
        method: 'post',
        data: parameter
    })
}

export function qLogin (mobilePhone) {
    return loginRequest({
        url: consts.url.LOGIN_Q,
        method: 'post',
        data: { mobilePhone }
    })
}
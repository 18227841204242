import { learningRequest, forwardRequest, learnUploadRequest, aiRequest, microRequest } from '@/utils/request'
import consts from '@/constants'
import md5 from "md5";

export function doQueryMicro (params) {
    return microRequest({
        url: consts.url.DO_QUERY_MICRO,
        method: 'post',
        data: params
    })
}

export function doActionMicro (params) {
    return microRequest({
        url: consts.url.DO_ACTION_MICRO,
        method: 'post',
        data: params
    })
}

export function doBaidu(params) {
    var url = params.url
    delete params.url
    return aiRequest({
        url: consts.url.DO_BAIDU + url,
        method: 'post',
        data: params
    })
}

export function doBaiduForm(url, formData) {
    return aiRequest({
        url: consts.url.DO_BAIDU + url,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;',
        },
        data: formData
    })
}

export function doBaiduGet(params) {
    var url = params.url
    delete params.url

    return aiRequest({
        url: consts.url.DO_BAIDU + url,
        method: 'get',
        params: {
            ...params
        },
    })
}

export function doQuery (params) {
    return learningRequest({
        url: consts.url.DO_QUERY,
        method: 'post',
        data: params
    })
}

export function doAction (params) {
    return learningRequest({
        url: consts.url.DO_ACTION,
        method: 'post',
        data: params
    })
}

export function doChat(params,progress,success,fail){

    fetch('/api-chat/askChat', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'fapp': 'chatapp',
            'apikey': '1df3eed2-b992-11ed-948c-00163e01246f'
        },
        body:JSON.stringify(
            params
        )
    }).then(response => {
        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();
        let readCount = 0;

        function readStreamData() {
            reader.read().then(({done,value}) => {

                if (done) {
                    reader.cancel();
                    return;
                }

                const data = textDecoder.decode(value);
                var buf = Buffer.from(data, 'hex').toString('utf8');

                //检查结束字符
                let i = buf.indexOf('#ENDMSG#');

                if (i == 0) {

                    //消息结束
                    if (success) success(buf);

                } else if (i > 0) {
                    if (success) success(buf.substring(i));
                    if (progress) progress(buf.substring(0, i))
                } else {

                    //消息未结束
                    if (progress) progress(buf)
                }

                readCount++;
                readStreamData();

            })
        }

        readStreamData();

    }).catch(error => {
        fail(error);
    })
}

export function doUpload(url,file) {
    var formData = new FormData()
    formData.append('file',file)
    return learnUploadRequest({
        url: url,
        //baseURL: '/dev-api',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
        },
        //baseURL: '/dev-api',
        data: formData
    })
}

export function sendSmsCode (phoneNumber) {
    return learningRequest({
        url: consts.url.SEND_SMS_CODE,
        method: 'post',
        data: {phoneNumber}
    })
}

export function doQueryForward (params) {
    return forwardRequest({
        url: consts.url.DO_QUERY_FORWARD,
        method: 'post',
        data: params
    })
}

export function doActionForward (params) {
    return forwardRequest({
        url: consts.url.DO_ACTION_FORWARD,
        method: 'post',
        data: params
    })
}
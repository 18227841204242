const business = {
    OK: 0,
    OK_MSG: '操作成功',
    FAIL_MSG: '操作失败',

    PRACTICE_TYPE: {
        2: {
            name: '电话拨测训练',
            icon: 'iconwhatsapp'
        },
        0: {
            name: '智能刷题训练',
            icon: 'iconbishitiku'
        },
        1: {
            name: '智能对话训练',
            icon: 'iconrengongzhinengdanao'
        },
    },

    /*任务类型*/
    TASK_TYPES:{
        INVEST: '10',
        EXAM: '20',
        LESSON: '30',
        SERIES: '40',
        SELF: '50',
        TRAIN: '90',
        COMP: '00'
    },

    TASK_TYPES_COLOR: {
        '10': 'purple',
        '20': 'red',
        '30': 'orange',
        '40': 'cyan',
        '90': 'blue'
    },


    /*考试题目类型*/
    QUES_TYPES:{
        SINGLE:'0',
        MULTI:'1',
        BLANK:'2',
        TEXT: '3',
        JUDGE: '4'
    },

    ANSWER_DICTIONARY: {
        '1': 'A',
        '2': 'B',
        '3': 'C',
        '4': 'D',
        '5': 'E',
        '6': 'F',
        'A': '1',
        'B': '2',
        'C': '3',
        'D': '4',
        'E': '5',
        'F': '6',
    },
}

const system = {
    TOKEN_VALID_MILLISECOND: 6 * 60 * 60 * 1000
}

//图片相关路径配置
const imgs={
    BASE_PATH: "https://img.wencaishui.com/wcs_pc/",
    BASE_PATH_TRAINING: "https://img.wencaishui.com/wcs_pc/lessons_training/",
    BASE_PATH_MICRO: "https://public.wencaishui.com/images/wcs/lessons_micro/",
    BASE_PATH_INDEX: "https://img.wencaishui.com/wcs_pc/index/",
    BASE_PATH_KDETAIL: "https://img.wencaishui.com/wcs_pc/kdetail/",
    BASE_PATH_QRCODE: "https://img.wencaishui.com/wcs_pc/qrcode/",
    BASE_PATH_VIP: "https://img.wencaishui.com/wcs_pc/vip/",
    BASE_PATH_REQUEST: "http://public.wencaishui.com/upload/request/",
    BASE_PATH_CMCLOUDS: "http://q.cmclouds.com/data/upload/images/",
    BASE_PATH_CMCLOUDSIMG: "http://q.cmclouds.com/static/mp/image/",
    IMG_POSTER_MICROVIDEO: "poster_microlesson.jpg",
    IMG_WENCAISHUI_QRCODE:'wencaishui_partner_pc.png',

    EMOJIS_PATH_PRE_OSS: 'https://public.wencaishui.com/images/wcs_huiwen_uni/emojis/',

    BASE_PATH_CMCLOUDS_SAFE: "https://q.cmclouds.com/data/upload/images/",
    BASE_PATH_OSS_ATTACH: "http://public.wencaishui.com/upload/request/attach/",
    BASE_PATH_OSS_ATTACH_HTTPS: "https://public.wencaishui.com/upload/request/attach/",
}

const oss = {
    BASE_PATH_MP: "https://q.cmclouds.com/static/mp/image/wcs/",
    EMOJIS_PATH_PRE_OSS: 'https://public.wencaishui.com/images/wcs_huiwen_uni/emojis/',
}

const url = {
    DO_BAIDU: '/unit/',
    DO_QUERY: '/open/core/proc/query/do/client',
    DO_ACTION: '/open/core/proc/action/do/client',
    DO_QUERY_FORWARD: '/open/core/proc/query/do/client/no-token',
    DO_ACTION_FORWARD: '/open/core/proc/action/do/client/no-token',
    UPLOAD_QUES_IMG_WANGEDITOR: '/admin/file/ques-image-wangeditor/upload',
    UPLOAD_IMG: '/admin/file/image/upload',
    UPLOAD_ATTACH: '/admin/file/attach/upload',
    UPLOAD_VIDEO: '/admin/file/video/upload',
    UPLOAD_FORUM_FILE: '/admin/file/lesson-forum-file/upload',
    LOGIN: '/open/user/login',
    GET_USER_INFO: '/admin/staff/info/get',
    SEND_SMS_CODE: '/admin/core/sms/send-verification-code',
    RESER_PASSWORD: '/admin/staff/password/reset',

    SEARCH_BY_MULTI: '/open/v2/lucene/kdb-index-all/search-by-multi',

    AI_START: '/open/v2/call-center/bot-train/start',
    AI_CALL:'/open/v2/call-center/bot-train/call',
    AI_END:'/open/v2/call-center/bot-train/end',
    AI_TTS:'/open/v2/call-center/tts/do',

    MSG_CHECK: '/mp/core/msgSecCheck.jhtml',

    DO_QUERY_MICRO: '/open/v2/core/proc/query/do',
    DO_ACTION_MICRO: '/open/v2/core/proc/action/do',

    LOGIN_Q: "/open/user/wcs-login",
    DO_QUERY_Q: '/mp/knowledge/doQuery.jhtml',
    DO_ACTION_Q: '/mp/knowledge/doAction.jhtml',
    ADD_REQUEST_REWARD: "/mp/reward/addRequestReward.jhtml",
    GET_REWARD_MSG: "/mp/reward/getRewardMsg.jhtml",
    REPLY_REQUEST_REWARD: "/mp/reward/replyRequestReward.jhtml",
    QUPLOAD_IMG: "/mp/file/uploadImg.jhtml",
}

const webSocket={
    WS_INIT_URL:"wss://api.cmclouds.com/v2/websocket",
    WS_TEST_URL:"ws://121.41.50.38:9888",
    WS_Q_INIT_URL: 'wss://q.cmclouds.com/websocket/socketServer.jhtml',
    WS_NOTICE_INIT_URL: 'wss://api.cmclouds.com/notification/websocket'
}

const storageKey={
    USER: 'user',
    STAFF: 'staff',
    LAST_LAUNCH_TIME_: 'last_launch_time_',
    XST_LESSON_PROGRESS_: 'xst_lesson_progress_',
    XST_SEARCH_LOG_: 'xst_search_log_',
    XST_VODEO_WATCH_COORDINATE_: 'xst_video_watch_coordinate_',
    XST_KNOWLEDGE_SEARCH_LOG_: 'xst_knowledge_search_log_',
}

//网络相关
const net={
    //凭证前缀
    BEARER_TYPE:"Bearer ",
    APP_KEY:"cmy8fe3d5f23d9a5afd",
    SIGNATURE_KEY:"cmy58510105"
}

export default {
    biz: business,
    sys: system,
    url: url,
    storageKey: storageKey,
    oss: oss,
    net: net,
    webSocket: webSocket,
    imgs: imgs
}

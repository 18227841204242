import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import app from './moudles/app'
import user from './moudles/user'
import staff from './moudles/staff'
import lesson from './moudles/lesson'
import kdbtype from './moudles/kdbtype'

import qsocket from './socket/qsocket'
import nsocket from './socket/noticesocket'

import getters from './getters'


const store = new Vuex.Store({
    modules: {
        app,
        user,
        staff,
        lesson,
        kdbtype,
        qsocket,
        nsocket
    },
    getters
})

export default store

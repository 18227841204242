import axios from 'axios'
import store from '../store'
import consts from '../constants'
import cmyUtils from './cmyUtils'
import router from '../router'
import CryptoJS from 'crypto-js'
import { notification,message } from 'ant-design-vue'


const beforeLearningRequest = (config) => {

    let params = null
    // get
    if (config.method === 'get') {
        params = config.params ? config.params : {}
    } else if (config.method === 'post') {
        params = config.data ? config.data : {}
    }

    var accessToken = store.getters.token
    if (accessToken) {
        config.headers['Access-Token'] = accessToken
    }

    if (config.method === 'post') {
        config.data = JSON.stringify(params)
    } else if (config.method === 'get') {
        config.params = params
    }
    return config
}

// 登录专用
const loginService = axios.create({
    baseURL: '/api',
    timeout: 15000
})

const beforeQRequest = (config) => {

    let params = null
    // get
    if (config.method === 'get') {
        params = config.params ? config.params : {}
    } else if (config.method === 'post') {
        params = config.data ? config.data : {}
    }

    // 如果token正常,将token放入header中
    var accessToken = store.getters.token
    if (accessToken != null) {
        params.token = accessToken
    }

    if (config.method === 'post') {
        config.data = JSON.stringify(params)
    } else if (config.method === 'get') {
        config.params = params
    }
    return config
}

const beforeLoginResponseErr = (error) => {
    if (String(error).substr(-3) === '401' || String(error).substr(-3) === '500' || String(error).substr(-3) === '403') {
        message.error('登录失败，请联系管理员')
    } else {
        console.log('请求异常：' + String(err))
    }
    return Promise.reject(error)
}

const beforeResponse = (response) => {
    return response.data
}

var boTokenInvalid = false

const beforeResponseErr = (error) => {
    if (String(error).substr(-3) === '401' || String(error).substr(-3) === '500' || String(error).substr(-3) === '403') {
        if (!boTokenInvalid) {
            boTokenInvalid = true
            notification.error({
                message: '您的登录信息已失效，即将重新登录'
            })
            setTimeout(() => {
                store.dispatch('Logout').then(() => {
                    router.replace({name: 'StudentLogin'})
                    boTokenInvalid = false
                })
            }, 2000)
        }
    } else {
        console.log('请求异常：' + String(error))
    }
    return Promise.reject(error)
}

const beforeRequestErr = (error) => {
    return Promise.reject(error)
}

loginService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
loginService.defaults.withCredentials = true
loginService.interceptors.request.use(beforeLearningRequest, beforeRequestErr)
loginService.interceptors.response.use(beforeResponse, beforeLoginResponseErr)

const learningService = axios.create({
    baseURL: '/api',
    timeout: 300000
})


learningService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
learningService.defaults.withCredentials = true
learningService.interceptors.request.use(beforeLearningRequest, beforeRequestErr)
learningService.interceptors.response.use(beforeResponse, beforeResponseErr)


const learnUploadService = axios.create({
    baseURL: '/api',
    timeout: 300000
})

const beforeLearningUploadRequest = (config) => {
    var accessToken = store.getters.token
    if (accessToken) {
        config.headers['Access-Token'] = accessToken
    }
    return config
}

learnUploadService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
learnUploadService.defaults.withCredentials = true
learnUploadService.interceptors.request.use(beforeLearningUploadRequest, beforeRequestErr)
learnUploadService.interceptors.response.use(beforeResponse, beforeResponseErr)

function beforeMicorRequest(config){
    let params = null;
    //get
    if(config.method=='get'){
        params = config.params ? config.params : {}
    }else if(config.method=='post'){
        params = config.data ? config.data : {};
    }

    params.appKey = consts.net.APP_KEY;

    //如果token正常,将token放入header中
    var accessToken = store.getters.qToken;
    if(null != accessToken){
        config.headers['Authorization'] = consts.net.BEARER_TYPE+accessToken;
    }

    //如果有querycode，则放入
    if (params.query_code)
        config.headers['X-QueryCode'] = params.query_code;

    //加密签名处理
    config.headers['X-Timestamp'] = cmyUtils.getSecondTimes();
    config.headers['X-Signature'] = getSignature(params);
    config.headers['X-AppKey'] = consts.net.APP_KEY;

    if(config.method=='post'){
        config.data=JSON.stringify(params);
    }else if(config.method=='get'){
        config.params=params;
    }
    return config;
}

const beforeMicroResponseErr = (error) => {
    if (String(error).substr(-3) === '401' || String(error).substr(-3) === '500' || String(error).substr(-3) === '403') {
        message.error('发生异常，请联系管理员')
    } else {
        console.log('请求异常：' + String(error))
    }
    return Promise.reject(error)
}

/**
 * 获取签名
 */
function getSignature(params) {
    if (typeof params === 'string') {
        return paramsStrSort(params)
    } else if (typeof params === 'object') {
        var arr = []
        for (var i in params) {
            arr.push((i + '=' + params[i]))
        }
        return paramsStrSort(arr.join(('$')))
    }
}
function paramsStrSort(paramStr) {
    let urlStr = paramStr.split('$').sort().join('&')
    // console.info('-> 请求参数字符串：' + urlStr)
    return CryptoJS.HmacSHA1(urlStr, consts.net.SIGNATURE_KEY) + ''
}

const microService = axios.create({
    baseURL: '/micro-api',
    timeout: 10000
})

const forwardService = axios.create({
    baseURL: '/api',
    timeout: 10000
})

microService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
microService.defaults.withCredentials = true
microService.interceptors.request.use(beforeMicorRequest, beforeRequestErr)
microService.interceptors.response.use(beforeResponse, beforeMicroResponseErr)


const beforeForwardResponseErr = (error) => {
    if (String(error).substr(-3) === '401' || String(error).substr(-3) === '500' || String(error).substr(-3) === '403') {
        message.error('阿偶，网络开小差了...')
    } else {
        console.log('请求异常：' + String(error))
    }
    return Promise.reject(error)
}

forwardService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
forwardService.defaults.withCredentials = true
forwardService.interceptors.request.use(beforeLearningRequest, beforeRequestErr)
forwardService.interceptors.response.use(beforeResponse, beforeForwardResponseErr)


const aiRequest = axios.create({
    // API 请求的默认前缀
    baseURL: '/ai',
    timeout: 300000 // 请求超时时间
})

// request interceptor
aiRequest.interceptors.request.use(config => {
    var token = store.getters.token
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Access-Token'] = token
    }

    return config
}, beforeRequestErr)

// response interceptor
aiRequest.interceptors.response.use((response) => {
    return response.data
}, beforeForwardResponseErr)


export {
    loginService as loginRequest,
    learningService as learningRequest,
    microService as microRequest,
    forwardService as forwardRequest,
    learnUploadService as learnUploadRequest,
    aiRequest
}

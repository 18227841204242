<template>
    <a-modal v-model="messageModal" :footer="null" @cancel="onModalClose" :width="680" :bodyStyle="{'max-height':'610px','overflow':'auto'}" centered>
        <template v-if="message">
            <template slot="title">
                <div style="padding: 0 20px;height: 47.6px;line-height: 47.6px;border-bottom: 1px solid #e8e8e8;">
                    <i class="iconfont icon-fanhui huiwen-pointer" @click="message = null"></i>
                    <span class="huiwen-margin-left-5">消息详情</span>
                </div>
            </template>
            <div class="huiwen-container">
                <div class="huiwen-container">
                    <span class="huiwen-text-bold huiwen-text-title">{{message.msg_title}}</span>
                </div>
                <div class="huiwen-container">
                    <span class="huiwen-text-gary-light huiwen-text-small-title">{{message.create_time}} | {{message.dept_name}}</span>
                    <span v-if="message.is_done == '1'" class="huiwen-right huiwen-text-green huiwen-text-small-title">已读</span>
                </div>
                <div class="huiwen-container huiwen-border-bottom-gray huiwen-margin-bottom-10 huiwen-margin-top-10"></div>
                <div v-if="message.msg_content" class="huiwen-bg-gray huiwen-border-box huiwen-container huiwen-text-black huiwen-text-small-title" style="line-height: 28px;padding: 0 5px;" v-html="message.msg_content"></div>
                <div v-else class="huiwen-bg-gray huiwen-container">
                    <a-empty style="padding: 10px 0"></a-empty>
                </div>
                <div v-if="message.need_confirm == '1'" class="huiwen-container huiwen-margin-top-10">
                    <a-button v-if="message.is_done == '0' && btnClock === 0" block type="primary"  @click="readMsgDetail()">确认已阅读</a-button>
                    <a-button v-if="message.is_done == '0' && btnClock === null" block type="primary" disabled>确认已阅读</a-button>
                    <a-button v-if="message.is_done == '0' && btnClock > 0" block type="primary" disabled>确认已阅读（{{btnClock}})</a-button>
                </div>
            </div>
        </template>
        <template v-else>
            <template slot="title">
                <a-menu mode="horizontal" :selectedKeys="[activeMenuIndex]" @click="onMessageClick">
                    <a-menu-item key="0"> 管理消息 </a-menu-item>
                    <a-menu-item key="1"> 平台消息 </a-menu-item>
                </a-menu>
            </template>
            <div class="huiwen-container">
                <div v-if="activeMenuIndex == '0'" class="huiwen-container">
                    <template v-if="managementMessages.length > 0">
                        <a-card v-for="(item, index) in managementMessages" hoverable style="margin-bottom: 10px" @click="showMsgDetail(activeMenuIndex,index)">
                            <a-card-meta>
                                <template slot="title">
                                    <i v-if="item.is_done == '0'" class="iconfont icon-dian1 huiwen-text-red">
                                        <span class="huiwen-text-bold huiwen-text-black huiwen-text-small-title">{{item.msgtype}}</span>
                                    </i>
                                    <span v-else class="huiwen-text-bold huiwen-text-black huiwen-text-small-title">{{item.msgtype}}</span>

                                    <i v-if="item.priority > '0' || item.need_confirm == '1'" class="iconfont icon-zhongyaotishi huiwen-right huiwen-text-red"></i>
                                </template>
                                <template slot="description">
                                    <span class="huiwen-text-small-title huiwen-text-gary-light">{{item.msg_title}}</span>
                                    <span class="huiwen-right huiwen-text-gary-light huiwen-text-small-title">{{item.dispatch_time}}</span>
                                </template>
                            </a-card-meta>
                        </a-card>
                    </template>
                    <template v-else>
                        <a-empty style="padding: 100px 0"></a-empty>
                    </template>
                    <cmy-page :page_now="mPage" :bo_end="mBoEnd" @next="queryMessage(activeMenuIndex, mPage + 1)" @previous="queryMessage(activeMenuIndex, mPage - 1)"></cmy-page>
                </div>
                <div v-if="activeMenuIndex == '1'" class="huiwen-container">
                    <template v-if="platformMessages.length > 0">
                        <a-card v-for="(item, index) in platformMessages" hoverable style="margin-bottom: 10px" @click="showMsgDetail(activeMenuIndex,index)">
                            <a-card-meta>
                                <template slot="title">
                                    <i v-if="item.is_done == '0'" class="iconfont icon-dian1 huiwen-text-red">
                                        <span class="huiwen-text-bold huiwen-text-black huiwen-text-small-title">{{item.msgtype}}</span>
                                    </i>
                                    <span v-else class="huiwen-text-bold huiwen-text-black huiwen-text-small-title">{{item.msgtype}}</span>

                                    <i v-if="item.priority > '0' || item.need_confirm == '1'" class="iconfont icon-zhongyaotishi huiwen-right huiwen-text-red"></i>
                                </template>
                                <template slot="description">
                                    <span class="huiwen-text-small-title huiwen-text-gary-light">{{item.msg_title}}</span>
                                    <span class="huiwen-right huiwen-text-gary-light huiwen-text-small-title">{{item.dispatch_time}}</span>
                                </template>
                            </a-card-meta>
                        </a-card>
                    </template>
                    <template v-else>
                        <a-empty style="padding: 100px 0"></a-empty>
                    </template>
                    <cmy-page :page_now="pPage" :bo_end="pBoEnd" @next="queryMessage(activeMenuIndex, pPage + 1)" @previous="queryMessage(activeMenuIndex, pPage - 1)"></cmy-page>
                </div>
            </div>
        </template>

    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core";
    import CmyPage from '@/components/cmy-page/cmy-page.vue'
    export default {
        name: "message-center",
        data() {
            return {
                messageModal: false,
                activeMenuIndex: '0',
                managementMessages: [],
                platformMessages: [],
                mPage: 1,
                pPage: 1,
                mLimit: 5,
                pLimit: 5,
                mBoEnd: false,
                pBoEnd: false,

                msgId: null,
                msgIndex: null,

                message: null,
                //是否能够设置已读状态
                btnClock: null
            }
        },
        components: {
            CmyPage
        },
        watch: {
            'visible': function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.queryMessage(this.activeMenuIndex,1)
                }
                this.messageModal = newVal
            }
        },
        props: {
            visible: Boolean
        },
        methods: {
            onModalClose() {
                const that = this
                that.message = null
                that.$emit('close')
            },
            onMessageClick({ key }) {
                const that = this
                that.activeMenuIndex = key
                that.queryMessage(key,1)
            },
            queryMessage(activeMenuIndex,page = activeMenuIndex == '0' ? this.mPage : this.pPage) {
                let that = this

                doQuery({
                    query_code: (activeMenuIndex === '0' ? 'LNM02' : 'LNM01'),
                    page: page,
                    limit: 5
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data ) {
                        if(activeMenuIndex === '0') {
                            let rsList = res.data
                            that.mPage = page
                            that.managementMessages = rsList
                            that.mBoEnd = res.data.length < that.mLimit ? true:false
                        }else if(activeMenuIndex === '1') {
                            let rsList = res.data
                            that.pPage = page
                            that.platformMessages = rsList
                            that.pBoEnd = res.data.length < that.pLimit ? true:false
                        }
                    }
                })
            },
            showMsgDetail(activeMenuIndex,index) {
                const that = this
                that.btnClock = null

                that.msgIndex = index
                if(activeMenuIndex == '0') {
                    that.msgId = that.managementMessages[index].msg_id
                }else if(activeMenuIndex == '1') {
                    that.msgId = that.platformMessages[index].msg_id
                }

                if(!that.msgId) {
                    return
                }
                doQuery({
                    query_code: 'LNM10',
                    param_str2: that.msgId,
                    page: 1,
                    limit: 10
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.message = res.data[0]
                        //如果需要手工确认，而且还没有确认阅读，则启动计数器，5秒以后可以设置成阅读
                        if (that.message.is_done == '0' && that.message.need_confirm == '1'){
                            that.startClock()
                        }
                    }
                })
            },
            startClock() {
                const that = this
                var timer = null
                that.btnClock = 5
                timer = setInterval(function() {
                    that.btnClock--
                    if (that.btnClock === 0){
                        clearInterval(timer)
                        timer=null
                    }
                }, 1000)
            },
            /**
             * 设置消息成已读状态
             */
            readMsgDetail: function() {
                const that = this;

                if(!that.msgId) {
                    that.$message.error('无法确认已读')
                    return
                }

                doAction({
                    action_code: 'LNM0',
                    param_str2: that.msgId,
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK ) {
                        if(res.data.errcode >= that.$consts.biz.OK) {
                            that.$message.success(that.$consts.biz.OK_MSG)
                            that.message.is_done = '1'
                            if(that.msgIndex) {
                                if(that.activeMenuIndex == '0') {
                                    var msg = {...that.managementMessages[that.msgIndex]}
                                    msg.is_done = '1'
                                    that.$set(that.managementMessages,that.msgIndex,msg)
                                }else if(that.activeMenuIndex == '1') {
                                    var msg = {...that.platformMessages[that.msgIndex]}
                                    msg.is_done = '1'
                                    that.$set(that.platformMessages,that.msgIndex,msg)
                                }
                            }
                        }else {
                            that.$message.error(that.$consts.biz.FAIL_MSG)
                        }
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                })
            },

        }
    }
</script>

<style scoped>
    /deep/ .ant-modal-header {
        padding: 6px 0px;
        border-bottom: none;
    }
</style>

const dateFormat = function(date,fmt){
  var o = {
    "M+": date.getMonth() + 1,                 //月份
    "d+": date.getDate(),                    //日
    "h+": date.getHours(),                   //小时
    "m+": date.getMinutes(),                 //分
    "s+": date.getSeconds(),                 //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

/**
 * 获取上个月的今天
 */
const getLastMonthToday= function(){

  var now = new Date();
  var year = now.getFullYear();//getYear()+1900=getFullYear()
  var month = now.getMonth() + 1;//0-11表示1-12月
  var day = now.getDate();
  if (parseInt(month) < 10) {
    month = "0" + month;
  }
  if (parseInt(day) < 10) {
    day = "0" + day;
  }

  now = year + '-' + month + '-' + day;

  if (parseInt(month) == 1) {//如果是1月份，则取上一年的12月份
    return (parseInt(year) - 1) + '-12-' + day;
  }

  var preSize = new Date(year, parseInt(month) - 1, 0).getDate();//上月总天数
  if (preSize < parseInt(day)) {//上月总天数<本月日期，比如3月的30日，在2月中没有30
    return year + '-' + month + '-01';
  }

  if (parseInt(month) <= 10) {
    return year + '-0' + (parseInt(month) - 1) + '-' + day;
  } else {
    return year + '-' + (parseInt(month) - 1) + '-' + day;
  }
};

/**
 * 获得几年前或者几年后的今天，不完善，特殊情况会有bug
 * count传负数则求几年前的今天，传正数则是几年后的今天
 */
const anyYearToday = function(count){
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth() + 1;
  var day = today.getDate();

  var new_year = parseInt(year)+count;
  var new_month = month;
  if (parseInt(month)<10){
    new_month = '0' + month;
  }
  return new_year + '-' + new_month + '-' + day;
};

/**
 * 获取当前时间
 */
const getNow = function(){
  var myDate = new Date();
  //获取当前年
  var year = myDate.getFullYear();
  //获取当前月
  var month = myDate.getMonth() + 1;
  //获取当前日
  var date = myDate.getDate();
  var h = myDate.getHours(); //获取当前小时数(0-23)
  var m = myDate.getMinutes(); //获取当前分钟数(0-59)
  var s = myDate.getSeconds();
  //获取当前时间
  var now = year + '-' + conver(month) + "-" + conver(date) + " " + conver(h) + ':' + conver(m) + ":" + conver(s);
  return now;
}

/**
 * 获取当前时间
 */
const getToday = function(){
  var myDate = new Date();
  //获取当前年
  var year = myDate.getFullYear();
  //获取当前月
  var month = myDate.getMonth() + 1;
  //获取当前日
  var date = myDate.getDate();
  //获取当前时间
  var now = year + '-' + conver(month) + "-" + conver(date);
  return now;
}

//日期时间处理
function conver(s) {
  return s < 10 ? '0' + s : s;
}


/* 获取秒级时间戳
 * @returns {string}
 */
function getSecondTimes(){
  var tmp = Date.parse( new Date()).toString();
  tmp = tmp.substr(0,10);
  tmp = parseInt(tmp);
  return tmp;
}

function addZero(str){
  if (parseInt(str) < 10)
    return '0' + str;
  else 
    return '' + str;
};
/**
 * 获取3个月之前的今天
 */
function getLast3MonthToday() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;//0-11表示1-12月
  var day = now.getDate();
  var dateObj = {};
  dateObj.now = year + '-' + month + '-' + day;
  var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
  if (month - 3 <= 0) { //如果是1、2、3月，年数往前推一年
    var last3MonthDay = new Date((year - 1), (12 - (3 - parseInt(month))), 0).getDate();    //3个月前所在月的总天数
    if (last3MonthDay < day) {    //3个月前所在月的总天数小于现在的天日期
      dateObj.last = (year - 1) + '-' + addZero((12 - (3 - month))) + '-' + addZero(last3MonthDay);
    } else {
      dateObj.last = (year - 1) + '-' + addZero((12 - (3 - month))) + '-' + addZero(day);
    }
  } else {
    var last3MonthDay = new Date(year, (parseInt(month) - 3), 0).getDate();    //3个月前所在月的总天数
    if (last3MonthDay < day) {    //3个月前所在月的总天数小于现在的天日期
      if (day < nowMonthDay) {        //当前天日期小于当前月总天数,2月份比较特殊的月份
        dateObj.last = year + '-' + addZero(month - 3) + '-' + addZero((last3MonthDay - (nowMonthDay - day)));
      } else {
        dateObj.last = year + '-' + addZero(month - 3) + '-' + addZero(last3MonthDay);
      }
    } else {
      dateObj.last = year + '-' + addZero(month - 3) + '-' + addZero(day);
    }
  }
  return dateObj.last;
};

export default{
  dateFormat,
  getLastMonthToday,
  anyYearToday,
  getNow,
  getToday,
  getSecondTimes,
  getLast3MonthToday
}

import { login, getInfo, qLogin } from '@/api/login'
import consts from '@/constants'
import { message } from 'ant-design-vue'

const user = {
    state: localStorage.getItem(consts.storageKey.USER) ? JSON.parse(localStorage.getItem(consts.storageKey.USER)) : {
        token: '',
        info: {
            departCode: '',
            deptName: '',
            headimgurl: '',
            mobilephone: '',
            name: '',
            posCode: '',
            posName: '',
        },
        qToken: '',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            localStorage.setItem(consts.storageKey.USER,JSON.stringify(state))
        },
        SET_USER_INFO: (state, info) => {
            state.info = info
            localStorage.setItem(consts.storageKey.USER,JSON.stringify(state))
        },
        SAVE_LOCAL_STORAGE:(state) => {
            localStorage.setItem(consts.storageKey.USER,JSON.stringify(state))
        },
        SET_Q_TOKEN: (state,qToken) => {
            state.qToken = qToken
            localStorage.setItem(consts.storageKey.USER, JSON.stringify(state))
        },
    },

    actions: {
        // 登录
        Login ({ commit, state }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    if (response.errcode === consts.biz.OK) {
                        commit('SET_TOKEN', response.data.access_token)
                        resolve(response)
                    }else if(response.errcode == '2106') {
                        message.error(response.errmsg)
                        reject(response)
                    }

                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo ({ commit }) {
            return new Promise((resolve, reject) => {
                getInfo().then(response => {
                    if (response.errcode === consts.biz.OK) {

                        console.log('userinfo:',response.data)

                        commit('SET_USER_INFO',response.data)
                        commit('SAVE_LOCAL_STORAGE')
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        Logout ({ commit, state }) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '')
                commit('SET_Q_TOKEN', '')
                localStorage.removeItem(consts.storageKey.USER)
                localStorage.removeItem(consts.storageKey.STAFF)
                resolve()
            })
        },

        QLogin({ commit }, mobilephone) {
            commit('SET_Q_TOKEN',null)
            return new Promise((resolve, reject) => {
                qLogin(mobilephone).then(res => {
                    if(res.errcode >= 0 && res.data) {
                        commit('SET_Q_TOKEN',res.data)
                        resolve()
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }

    }
}

export default user

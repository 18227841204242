import consts from '@/constants'
import { Modal } from "ant-design-vue";
const qsocket = {
// 全局 state 对象，用于保存所有组件的公共数据
    state: {
        //客户端
        qws: null,
        qtime: 15000,
        qtimeTasks: [],
    },
    // 定义改变 state 初始值的方法，这里是唯一可以改变 state 的地方，缺点是只能同步执行
    mutations: {
        INIT_WS(state,{ getSockMsg, openCallback, errorCallback,requestId,websocket_key}) {
            this.commit('CLOSE_WS')
            console.log("qws 初始化");

            var url = 'ws://' + location.host + '/qws/websocket/socketServer.jhtml?websocket_key=' + encodeURIComponent(websocket_key + '&requestId=' + requestId)
            if(process.env.NODE_ENV === 'production') {
                url = consts.webSocket.WS_Q_INIT_URL + '?websocket_key=' + encodeURIComponent(websocket_key + '&requestId=' + requestId)
            }

            let socket = new WebSocket(url);
            //let socket = new WebSocket('ws://121.40.165.18:8800');
            socket.onmessage = res => {
                //const rs = JSON.parse(res.data);
                console.log("qws 收到消息", res);
                //收到success代表心跳正常
                if (res.data == "success") {
                    return;
                } else {
                    let resurl = res.currentTarget.url;
                    resurl = unescape(resurl);
                    getSockMsg(res.data,resurl.substring(resurl.indexOf('&requestId=')+11))
                }
            }

            socket.onerror = e => {
                console.log('err=======>',e)
                if (state.qws && 1 == state.qws.readyState) {
                    Modal.error({
                        title: '告警信息',
                        content: '连接发生错误',
                    })
                    state.qws = null;
                    this.commit('RESET_HEART_CHECK')
                }
                errorCallback()
            }

            socket.onclose = res => {
                console.log('close qws...............',res)
                state.qws = null;
                this.commit('RESET_HEART_CHECK')
            }

            socket.onopen = () =>{
                console.log('open qws')
                state.qws = socket;
                this.commit('START_HEART_CHECK')
                openCallback()
            }

        },
        RESET_HEART_CHECK(state) {
            state.qtimeTasks.forEach(task => {
                console.log('清除遗留的时间任务qws',task)
                clearInterval(task)
            })
            state.qtimeTasks = [];
        },
        START_HEART_CHECK(state) {
            if (state.qws && 1 == state.qws.readyState) {
                var task = setInterval(()=>{
                    state.qws.send('ping')
                },state.qtime)
                state.qtimeTasks.push(task)
            }
        },
        SEND_MSG(state,msg){
            if (state.qws && 1 == state.qws.readyState) {
                console.log('send===>', msg)
                state.qws.send(msg)
            }
        },
        CLOSE_WS(state) {
            if (state.qws && 1 == state.qws.readyState) {
                console.log('清除遗留的qws')
                state.qws.close()
                state.qws = null;
                this.commit('RESET_HEART_CHECK')
            }
        }
    },
    // 定义触发 mutations 里函数的方法，可以异步执行 mutations 里的函数
    actions: {

    }
}

export default qsocket;


const lesson = {
    state: {
        searchText: '',
        trainingId: null,
        trainingVisible: false,
        // 注意：这里的true false 不代表开关 而是为了利用 refreshTrainingTaskItem = !refreshTrainingTaskItem 配合watch 只要有变动就刷新
        refreshTrainingTaskItem: true,

        seriesId: null,
        seriesVisible: false,
        refreshSeriesTaskItem: true
    },
    mutations: {
        SET_SEARCH_TEXT: (state, searchText) => {
            state.searchText = searchText
        },
        SET_TRAINING_ID: (state, trainingId) => {
            state.trainingId = trainingId
        },
        SET_TRAINING_VISIBLE: (state, trainingVisible) => {
            state.trainingVisible = trainingVisible
        },
        SET_REFRESH_TRAINING_TASK_ITEM: (state,refreshTrainingTaskItem) => {
            state.refreshTrainingTaskItem = refreshTrainingTaskItem
        },
        SET_SERIES_ID: (state, seriesId) => {
            state.seriesId = seriesId
        },
        SET_SERIES_VISIBLE: (state, seriesVisible) => {
            state.seriesVisible = seriesVisible
        },
        SET_REFRESH_SERIES_TASK_ITEM: (state,refreshSeriesTaskItem) => {
            state.refreshSeriesTaskItem = refreshSeriesTaskItem
        },
    },

    actions: {

    }
}

export default lesson

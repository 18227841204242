<template>
    <a-layout>
        <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }" class="huiwen-layout-flex-center" >
            <div style="width: 1200px">
                <div class="huiwen-left huiwen-layout-flex-center" style="height: 64px;">
                    <img :src="logo" style="height: 50px"/>
                </div>
                <a-menu
                        theme="dark"
                        class="huiwen-left"
                        mode="horizontal"
                        :selectedKeys="[$route.name]"
                        :default-selected-keys="['2']"
                        :style="{ 'line-height': '64px', 'margin-left': '50px' }"
                        @click="onMenuSelect"
                >
                    <a-menu-item key="Index">
                        首页
                    </a-menu-item>
                    <a-menu-item key="TrainingService">
                        解决方案
                    </a-menu-item>
                    <a-menu-item key="AboutUs">
                        关于我们
                    </a-menu-item>
                    <a-menu-item key="ContactUs">
                        联系我们
                    </a-menu-item>
                </a-menu>
                <div class="huiwen-right huiwen-text-white huiwen-text-common huiwen-pointer" style="width: 230px;">
                    <div class="huiwen-left huiwen-text-center" style="width:100px;">
                    <router-link to="/student/login" class="login-btn">学员登录</router-link>
                    </div>
                    <div class="huiwen-left huiwen-text-center" style="width:120px;">
                    <router-link class="huiwen-margin-left-10 login-btn2"  to="" @click.native="toAdmin()">管理企业</router-link>
                    </div>
                </div>
            </div>
        </a-layout-header>
        <a-layout-content>
            <div class="huiwen-container huiwen-left" :style="{'height':'64px'}"></div>
            <div class="huiwen-container huiwen-left" :style="{ 'background': '#fff', 'min-height': (winSize[1] - 222) + 'px' }">
                <router-view></router-view>
            </div>
        </a-layout-content>
        <a-layout-footer :style="{'padding':'60px 0 10px','background': '#333333' }" class="huiwen-layout-flex-lr-center huiwen-border-box">
            <div style="width:1043px">
                <a-row :gutter="8">
                    <a-col :span="8" class="huiwen-text-center" style="color: rgb(123, 123, 123);font-size: 14px">
                        地址：苏州市工业园区星湖街328号创意产业园6栋
                    </a-col>
                    <a-col :span="8" class="huiwen-text-center" style="color: rgb(123, 123, 123);font-size: 14px">
                        Tel/联系电话：0512-62512366
                    </a-col>
                    <a-col :span="8" class="huiwen-text-center" style="color: rgb(123, 123, 123);font-size: 14px">
                        Mail/邮箱：service@huiwen.com
                    </a-col>
                </a-row>
                <a-row class="huiwen-text-center" style="margin-top: 30px;color: rgb(123, 123, 123);font-size: 12px">
                    ©2021 学税通 版权所有 <a rel="nofollow" href="https://beian.miit.gov.cn">苏ICP备14039661号-7</a>
                </a-row>
                <a-row class="huiwen-text-center" style="color: rgb(123, 123, 123);font-size: 12px">
                    技术支持：上海慧问信息科技有限公司
                </a-row>
            </div>
        </a-layout-footer>
    </a-layout>
</template>

<script>
    import logo from '@/assets/logo_title.png'
    import {mapGetters} from 'vuex'
    export default {
        name: "BasicLayout",
        data() {
            return {
                logo: logo
            }
        },
        computed: {
            ...mapGetters([
                'winSize',
            ]),
        },
        methods: {
            onMenuSelect({ item, key, selectedKeys }) {
                const that = this
                that.$router.push({ name: key })
            },
            toAdmin() {
                const that = this
                window.location.href="https://adminportal.xueshuitong.com"
            }
        }
    }
</script>

<style scoped>

    .login-btn {
        color: white;
        border: 1px solid white;
        padding: 2px 20px;
        border-radius: 11px;
    }
    .login-btn2 {
        color: white;
        border: 1px solid #1890ff;
        padding: 2px 20px;
        border-radius: 11px;
        background: #1890ff;
    }
    /deep/ .ant-menu-item, .ant-menu-submenu-title{
        padding: 0 30px !important;
    }

</style>

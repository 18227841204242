import { doQuery } from "@/api/core"
import store from '@/store'
import consts from '@/constants'

const kdbtype = {
    state: {
        //知识父类型数组，每个元素为：{pkdbtype_code,pkdbtype,txttype}
        pkdbtypes: null,
        //知识类型数组，每个元素为：{kdbtype_code,kdbtype,pkdbtype_code}
        kdbtypes: null,
    },
    mutations: {
        SET_PKDBTYPES:(state, pkdbtypes) => {
            state.pkdbtypes = pkdbtypes
        },
        SET_KDBTYPES:(state, kdbtypes) => {
            state.kdbtypes = kdbtypes
        },
    },

    actions: {
        RefreshPkdbtypes({ commit }) {
            return new Promise((resolve, reject) => {
                if (!store.getters.getPkdbtypes  || store.getters.getPkdbtypes.length == 0) {
                    doQuery({
                        query_code: 'LNH00',
                        page: 1,
                        limit: 1
                    }).then(res => {
                        if(res.errcode >= consts.biz.OK && res.data) {
                            commit('SET_PKDBTYPES',res.data)
                            resolve()
                        }
                    })
                }else {
                    resolve()
                }
            })
        },
        RefreshKdbtypes({ commit }) {
            return new Promise((resolve, reject) => {
                if (!store.getters.getKdbtypes || store.getters.getKdbtypes.length == 0) {
                    doQuery({
                        query_code: 'LNH10',
                        page: 1,
                        limit: 1
                    }).then(res => {
                        if(res.errcode >= consts.biz.OK && res.data) {
                            commit('SET_KDBTYPES',res.data)
                            resolve()
                        }
                    })
                }else {
                    resolve()
                }
            })
        },
        /**
         * 获取父类型: 根据子类型code，获取父类型名称
         */
        GetPkdbtypeByKdbtypeCode:({ commit},kdbtypeCode) =>{
            return new Promise((resolve,reject) => {
                var pkdbtypes = store.getters.getPkdbtypes
                var res = null
                for (var i = 0 ;i < pkdbtypes.length;i++){
                    if (pkdbtypes[i].pkdbtype_code == kdbtypeCode.substr(0,1)){
                        res =  pkdbtypes[i].pkdbtype
                        break
                    }
                }
                resolve(res)
            })
        },
        /**
         * 获取txttype：根据子类型code，获取父类型的txttype（用来存放一些类型的标记性文字）
         */
        GetTxttypeByKdbtypeCode:({ commit},kdbtypeCode) => {
            return new Promise((resolve,reject) => {
                var pkdbtypes = store.getters.getPkdbtypes
                var res = null
                for (var i = 0 ;i < pkdbtypes.length;i++){
                    if (pkdbtypes[i].pkdbtype_code == kdbtypeCode.substr(0,1)){
                        res = pkdbtypes[i].txttype
                        break
                    }
                }
                resolve(res)
            })
        },
        /**
         * 获取子类型数组：根据父类型编码获取子类型编码数组
         */
        GetKdbtypeCodesByPkdbtypeCode:({ commit},pkdbtypeCode) =>{
            return new Promise((resolve,reject) => {
                var kdbtypeCodes = []
                var kdbtypes = store.getters.getKdbtypes
                kdbtypes.forEach(function(item,index){
                    if (item.pkdbtype_code == pkdbtypeCode) {
                        kdbtypeCodes.push(item.kdbtype_code)
                    }
                })
                resolve(kdbtypeCodes)
            })
        },
        /**
         * 获取子类型数组
         *
         */
        GetKdbtypesByPkdbtypeCode:({ commit },pkdbtypeCode) => {
            return new Promise((resolve,reject) => {
                var kdbtypes = []
                store.getters.getKdbtypes.forEach(function(item,index){
                    if (item.pkdbtype_code == pkdbtypeCode) {
                        kdbtypes.push(
                            {
                                kdbtype_code: item.kdbtype_code,
                                kdbtype: item.kdbtype
                            }
                        )
                    }
                })
                resolve(kdbtypes)
            })
        }
    },
}

export default kdbtype

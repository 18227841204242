<template>
    <a-config-provider :locale="locale">
        <div id="app" ref="app">
            <router-view v-if="isRouterAlive"></router-view>
        </div>
    </a-config-provider>
</template>

<script>

    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import {mapGetters, mapMutations} from 'vuex'

    export default {
        name: 'App',
        data() {
            return {
                locale: zhCN,
                isRouterAlive: true
            }
        },
        computed: {
            ...mapGetters([
                'winSize'
            ]),
        },
        provide() {
            return {
                reload: this.reload,
                toBottom: this.toBottom
            }
        },
        mounted() {
            const that = this
            window.onresize = () => {
                that.SET_WIN_SIZE([document.documentElement.clientWidth, document.documentElement.clientHeight])
            }
        },
        methods: {
            ...mapMutations([
                'SET_WIN_SIZE',
            ]),
            reload() {
                const that = this
                that.isRouterAlive = false
                that.$nextTick(function () {
                    that.isRouterAlive = true
                })
            },
            toBottom() {
                const that = this
                that.$nextTick(() => {
                    document.documentElement.scrollTop = document.documentElement.scrollHeight
                })
            }
        }
    }
</script>

<style>
    @import './style/huiwen-business.css';
    @import './style/huiwen-common.css';

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>

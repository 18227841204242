import Vue from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import router from './router'
import store from './store'
import consts from './constants'
import cmyUtils from './utils/cmyUtils'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import { Icon } from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2614947_mcp150qgsen.js',
});
Vue.component('icon-font', IconFont)

Vue.config.productionTip = false

Vue.use(Antd)

import validateUtils from './utils/validate'
Vue.prototype.$validateUtils = validateUtils

Vue.prototype.$consts = consts
Vue.prototype.$cmyUtils = cmyUtils

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video //引入Video播放器


import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment;

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true, // 是否启用inline模式
    'button': true, // 是否显示右上角关闭按钮
    'navbar': true, // 是否显示缩略图底部导航栏
    'title': true, // 是否显示当前图片标题，默认显示alt属性内容和尺寸
    'toolbar': true, // 是否显示工具栏
    'tooltip': true, // 放大或缩小图片时，是否显示缩放百分比，默认true
    'fullscreen': true, // 播放时是否全屏，默认true
    'loading': true, // 加载图片时是否显示loading图标，默认true
    'loop': true, // 是否可以循环查看图片，默认true
    'movable': true, // 是否可以拖得图片，默认true
    'zoomable': true, // 是否可以缩放图片，默认true
    'rotatable': true, // 是否可以旋转图片，默认true
    'scalable': true, // 是否可以翻转图片，默认true
    'toggleOnDblclick': true, // 放大或缩小图片时，是否可以双击还原，默认true
    'transition': true, // 使用 CSS3 过度，默认true
    'keyboard': true, // 是否支持键盘，默认true
    'zoomRatio': 0.1, // 鼠标滚动时的缩放比例，默认0.1
    'minZoomRatio': 0.01, // 最小缩放比例，默认0.01
    'maxZoomRatio': 100, // 最大缩放比例，默认100
    'url': 'data-source' // 设置大图片的 url
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

function validateMobilePhone(rule, value, callback){
    if (value === '') {
        callback(new Error('手机号不可为空'))
    } else {
        var reg = /^1[3456789]\d{9}$/
        if(!reg.test(value)){
            callback(new Error('请输入有效的手机号码'))
            return
        }
        callback()
    }
}

function validatePassword(rule, value, callback){
    if (value === '') {
        callback(new Error('密码不可为空'))
    } else {
        if(value.length < 6){
            callback(new Error('请输入不小于6位数的密码'))
            return
        }
        callback()
    }
}

export default {
    validateMobilePhone,
    validatePassword
}

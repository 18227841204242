import consts from '@/constants'
import { Modal } from "ant-design-vue";

const noticesocket = {
// 全局 state 对象，用于保存所有组件的公共数据
    state: {
        //客户端
        nws: null,
        ntime: 15000,
        ntimeTasks: [],
        callbacks:null,
        retrys:0
    },
    // 定义改变 state 初始值的方法，这里是唯一可以改变 state 的地方，缺点是只能同步执行
    mutations: {
        INIT_NOTICE_WS(state,{ getSockMsg, openCallback, errorCallback, acctId}) {
            this.commit('CLOSE_NOTICE_WS')
            console.log("nws 初始化");

            //var socket = new WebSocket('ws://121.40.165.18:8800')

            var socket = new WebSocket(consts.webSocket.WS_NOTICE_INIT_URL + '?userId=' + acctId + '&key=30f37a39-4e58-45e2-8267-ee5b2576d0b1');

            state.callbacks  = { getSockMsg, openCallback, errorCallback, acctId};

            socket.onmessage = res => {
                //const rs = JSON.parse(res.data);
                console.log("nws 收到消息", res);
                //收到success代表心跳正常
                if (res.data == "success" || res.data == 'connected') {
                    return;
                } else {
                    var rs = JSON.parse(res.data)
                    const { content } = rs.data
                    var contentObj = JSON.parse(content)
                    getSockMsg({...contentObj})
                }
            }

            socket.onerror = e => {
                console.log('err=======>',e)
                if (state.nws && 1 == state.nws.readyState) {
                    Modal.error({
                        title: '告警信息',
                        content: '连接发生错误',
                    })
                    state.nws = null;
                    this.commit('RESET_NOTICE_HEART_CHECK')
                }
                errorCallback()
            }

            socket.onclose = res => {
                console.log('close nws...............',)
                state.nws = null;
                this.commit('RESET_NOTICE_HEART_CHECK')

                //满足如下条件，重新链接:尝试次数小于6次，而且回调方法有效 ,非正常关闭，网络问题断开
                let that = this;
                if (state.retrys < 6 &&
                    state.callbacks &&
                    !res.wasClean &&
                    res.code == 1006)
                    setTimeout(function() {
                        ++state.retrys;

                        console.log('第' + state.retrys + '次尝试重新连接nws...')
                        that.commit('INIT_NOTICE_WS', state.callbacks);
                    },10000);

                else if (state.retrys >= 6) {
                    Modal.error({
                        title: '告警信息',
                        content: '网络连接已经断开，可能收不到通知消息，请检查后重新登录！',
                    })
                }

            }

            socket.onopen = () =>{
                console.log('open nws')
                state.nws = socket;
                state.retrys = 0;
                this.commit('START_NOTICE_HEART_CHECK')
                openCallback()
            }

        },
        RESET_NOTICE_HEART_CHECK(state) {
            state.ntimeTasks.forEach(task => {
                console.log('清除遗留的时间任务nws',task)
                clearInterval(task)
            })
            state.ntimeTasks = [];
        },
        START_NOTICE_HEART_CHECK(state) {
            if (state.nws && 1 == state.nws.readyState) {
                var task = setInterval(()=>{
                    state.nws.send('ping')
                },state.ntime)
                state.ntimeTasks.push(task)
            }
        },
        SEND_NOTICE_MSG(state,msg){
            if (state.nws && 1 == state.nws.readyState) {
                state.nws.send(msg)
            }
        },
        CLOSE_NOTICE_WS(state) {
            if (state.nws && 1 == state.nws.readyState) {
                console.log('清除遗留的nws')
                state.nws.close()
                state.nws = null;
                this.commit('RESET_NOTICE_HEART_CHECK')
            }
        },
        RESET_NOTICE_WS_ONMESSAGE(state, func) {
            if (state.nws && 1 == state.nws.readyState) {
                state.nws.onmessage = res => {
                    console.log("nws 收到消息", res);
                    //收到success代表心跳正常
                    if (res.data == "success" || res.data == 'connected') {
                        return;
                    } else {
                        var rs = JSON.parse(res.data)
                        const { content } = rs.data

                        var contentObj = JSON.parse(content.replace(/\n/g,"\\n").replace(/\r/g,"\\r"));

                        func({...contentObj})
                    }
                }
            }
        }
    },
    // 定义触发 mutations 里函数的方法，可以异步执行 mutations 里的函数
    actions: {

    }
}

export default noticesocket;

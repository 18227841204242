
//获取当前时间（'2018-10-20 12:30:30')
function getCurrentTime() {
    var date = new Date();

    var month = date.getMonth() + 1;
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }

    var strDate = date.getDate();
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }

    var hour = date.getHours();
    if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
    }

    var minute = date.getMinutes();
    if (minute >= 0 && minute <= 9) {
        minute = "0" + minute;
    }

    var second = date.getSeconds();
    if (second >= 0 && second <= 9) {
        second = "0" + second;
    }

    var currentdate = date.getFullYear() + "-" + month + "-" + strDate + " " + hour + ":" + minute + ":" + second;
    return currentdate;
}

/**
 * 获取秒级时间戳
 * @returns {string}
 */
var getSecondTimes = function () {
    var tmp = Date.parse(new Date()).toString()
    tmp = tmp.substr(0, 10)
    tmp = parseInt(tmp)
    return tmp
}

var isMobileNumber = (rule, value, callback) => {
    if (!value) {
        return new Error('请输入电话号码')
    } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        // 转换为数字
        value = Number(value)
        if (typeof value === 'number' && !isNaN(value)) {
            // 判断是否为数字
            value = value.toString()
            // 转换成字符串
            if (value.length < 0 || value.length > 12 || !isPhone) {
                // 判断是否为11位手机号
                callback(new Error('手机号码格式如:139****9697'))
            } else {
                callback()
            }
        } else {
            callback(new Error('请输入电话号码'))
        }
    }
}

var isValidateSMSCode = (rule, value, callback) => {
    if (!value) {
        return new Error('请输入验证码')
    } else if (value.length < 4) {
        callback(new Error('验证码不能小于4位'))
    } else {
        callback()
    }
}

var isValidatePass = (rule, value, callback) => {
    if (!value) {
        return new Error('请输入密码')
    } else if (value.length < 4) {
        callback(new Error('密码不能小于4位'))
    } else {
        callback()
    }
}

var boMobileNumber = (value) => {
    if (!value) {
        return false
    } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value)
        // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
            // 判断是否为数字
            value = value.toString()
            // 转换成字符串
            if (value.length < 0 || value.length > 12 || !isPhone) {
                // 判断是否为11位手机号
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }
}

// 时间转换
var formatMsgTime = (timespan) => {
    var dateTime = new Date(timespan * 1000)

    var year = dateTime.getFullYear()
    var month = dateTime.getMonth() + 1
    var day = dateTime.getDate()
    var hour = dateTime.getHours()
    var minute = dateTime.getMinutes()
    var second = dateTime.getSeconds()
    var now = new Date()
    var now_new = now.getTime()

    // 补充0
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    if (hour >= 0 && hour <= 9) {
        hour = '0' + hour
    }
    if (minute >= 0 && minute <= 9) {
        minute = '0' + minute
    }
    if (second >= 0 && second <= 9) {
        second = '0' + second
    }

    var milliseconds = 0
    var timeSpanStr

    milliseconds = now_new - timespan * 1000

    if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = '刚刚'
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
        timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
    } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
    } else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
    } else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year == now.getFullYear()) {
        timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
    } else {
        timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    }
    return timeSpanStr
}

// 去左右空格;
var trim = (s) => {
    return s.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * 对象数组中查找
 */
var findElement = (arrayToSearch, attr, val) => {
    for (var i = 0; i < arrayToSearch.length; i++) {
        if (arrayToSearch[i][attr] == val) {
            return i
        }
    }
    return -1
}

function includeNumber(str) {
    var reg = /\d+/;
    return reg.test(str);
}

var fuzzyStr = (str) => {
    /*
    var newStr = str.trim().replace(/\s+/g, '*')
    newStr = '*' + newStr + '*'
    */
    var oldStr = String(str.trim());
    var newStr = '*';

    //每个字符增加*
    for (var i = 0;i<oldStr.length;i++){
        newStr = newStr + oldStr.charAt(i) + '*'
    }

    //空格替换成*
    newStr = newStr.replace(/\s+/g, '')

    return newStr
}

var unFuzzyStr = (str) => {

    //var newStr = str.replace(/\*/g, ' ')
    //newStr = newStr.trim()
    //每两个**为一个空格
    var newStr = str.replace(/\*\*/g,' ');

    //如果还有空格，则去除即可
    newStr = newStr.replace(/\*/g,'');

    return newStr
}

var getStartDateOfYear = (year) => {
    if (year == '全部') {
        return ''
    }
    if (year.indexOf('以前') >= 0 || year.indexOf('前') >= 0) {
        return '1900-01-01'
    } else {
        return year.substr(0, 4) + '-01-01'
    }
}

var getEndDateOfYear = (year) => {
    if (year == '全部') {
        return ''
    }
    if (year.indexOf('以前') >= 0 || year.indexOf('前') >= 0) {
        return (parseInt(year.substr(0, 4)) - 1) + '-12-31'
    } else {
        return year.substr(0, 4) + '-12-31'
    }
}

/**
 * 分割数组
 * @param arr
 * @param size
 * @returns {Array}
 */
var chunk = (arr, size) => {
    var arr2 = []
    for (var i = 0; i < arr.length; i = i + size) {
        arr2.push(arr.slice(i, i + size))
    }
    return arr2
}

var clearTxt = (str) => {
    var newstr = str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, '')
    newstr = newstr.replace(/[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g, '')
    return newstr
}


var getBoSearchEnd = (length, limit) => {
    if (length < limit) {
        return true
    } else {
        return false
    }
}

var getQueryVariable = (url, variable) => {
    var query = url.substring(url.indexOf('?') + 1)
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}

var addDate = function (date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    if (month < 10) {
        month = '0'.concat(String(month));
    }
    if (day < 10) {
        day = '0'.concat(String(day));
    }
    return d.getFullYear() + '-' + month + '-' + day;
}

/**
 * 时间转换，如将100秒转换为1分40秒
 */
var formatSeconds = (s) => {

    var m = parseInt(s / 60);
    var i = Math.floor(s - m * 60);

    return m + '分' + i + '秒';

}

var getReleaseYearList = () => {
    var date = new Date()
    let yearNow = Number(date.getFullYear())
    let arr = ['全部']
    for (var i = 0; i < 4; i++) {
        let year = yearNow - i
        arr.push(String(year).concat('年'))
        if (i == 3) {
            arr.push(String(year).concat('年前'))
        }
    }
    return arr
}

/**
 * 获取当前时间
 */
var getToday = function(format = 'yyyy-MM-dd'){
    var myDate = new Date();
    //获取当前年
    var year = myDate.getFullYear();
    //获取当前月
    var month = myDate.getMonth() + 1;
    //获取当前日
    var date = myDate.getDate();
    //获取当前时间
    var now = year + '-' + conver(month) + "-" + conver(date);
    if(format == 'yyyyMMdd'){
        now = year + conver(month) + conver(date);
    }
    return now;
}

//日期时间处理
function conver(s) {
    return s < 10 ? '0' + s : s;
}

function keepLastIndex(obj) {
    if (window.getSelection) {//ie11 10 9 ff safari
        obj.focus(); //解决ff不获取焦点无法定位问题
        var range = window.getSelection();//创建range
        range.selectAllChildren(obj);//range 选择obj下所有子内容
        range.collapseToEnd();//光标移至最后
    }
    else if (document.selection) {//ie10 9 8 7 6 5
        var range = document.selection.createRange();//创建选择对象
        //var range = document.body.createTextRange();
        range.moveToElementText(obj);//range定位到obj
        range.collapse(false);//光标移至最后
        range.select();
    }
}

var isPositiveInteger = (rule, value, callback) => {
    const reg = /^([1-9]\d*|[0]{1,1})$/
    const isPositiveInteger = reg.test(value)
    if (isPositiveInteger) {
        callback()
    } else {
        callback(new Error('请输入一个正整数'))
    }
}

var isPositiveInteger0To100 = (rule, value, callback) => {
    const reg = /^([1-9]\d*|[0]{1,1})$/
    const isPositiveInteger = reg.test(value)
    if (isPositiveInteger) {
        if(Number(value) > 100) {
            callback(new Error('超过最大可输入数值：100'))
        }else {
            callback()
        }
    } else {
        callback(new Error('请输入一个正整数'))
    }
}

/**
 * 时间转换，如将100秒转换为1分
 */
var formatMinutes = (s) => {
    var m = parseInt(s / 60)
    return m + '分'
}

var sortAnswer = (num) => {
    var ret = '';
    if (num.indexOf('1')>=0) ret = ret + '1|';
    if (num.indexOf('2')>=0) ret = ret + '2|';
    if (num.indexOf('3')>=0) ret = ret + '3|';
    if (num.indexOf('4')>=0) ret = ret + '4|';
    if (num.indexOf('5')>=0) ret = ret + '5|';
    if (num.indexOf('6')>=0) ret = ret + '6|';

    //去掉最后一个|
    if (ret != '') ret = ret.substr(0,ret.length - 1);

    return ret;
}

var convertAnswer = (num) => {
    return num.replace('1','A').replace('2','B').replace('3','C').replace('4','D').replace('5','E').replace('6','F')
}

//scrstr 源字符串 armstr 特殊字符
function getStrCount(scrstr,armstr) {
    var count=0
    var reg = new RegExp(' ' , "g")
    scrstr = scrstr.replace(reg,'')
    while(scrstr.indexOf(armstr) != -1 ) {
        scrstr = scrstr.replace(armstr,"")
        count++
    }
    return count
}

function getFileType(url_src) {
    var file_type = url_src.substr(url_src.trim().lastIndexOf('.') + 1).toLowerCase();
    return file_type;
}

/**
 * 时间转换，如将100秒转换为1分40秒
 */
function formatMinutesEx(s) {

    if (s<0)
        return '0天0时0分';

    var s1 = s;
    var d = parseInt(s1 / (24*60));

    var s2 = Math.floor(s1 - d * 24 * 60);
    var h = parseInt(s2 / 60);

    var m = Math.floor(s2 - h * 60);

    return d +'天' + h + '时' + m + '分';
}

//获取某个时间与当前时间的时间差距,d2 - d1
function getDateDiffMinutes(d){

    var t1 = new Date().getTime();
    var t2 = new Date(Date.parse(d.replace(/-/g,   "/"))).getTime();

    var diff = t2 - t1;
    return Math.floor(diff/(60*1000));
}

function convertAnswer2(num) {
    return num.replace('1','A').replace('2','B').replace('3','C').replace('4','D').replace('5','E').replace('6','F').split('|').join('，')
}

function clearEmoji(str){
    var newstr = str.replace(/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/g,"");
    return newstr;
}

function clearBackSlash(str) {
    return str.replace(/'\'/g,'')
}

/**去除Html标签 */
function deleteHTMLTag(s) {
    var dd = s.replace(/<\/?.+?>/g, "");
    var dds = dd.replace(/[\r\n]/g, " ");
    return dds;
}

function buildUnitPath(url) {
    return url + '?key=4A6F70D4-54C9-440C-823A-01FB1BC33C54'
}

export function getTreeData(nodeData, rootValue,icon) {
    let treeData = []

    nodeData.forEach(item => {

        //找到父节点是当前节点id的项目，或者两个都是null/undefined
        if (item.pId === rootValue || (!rootValue && !item.pId)) {

            //获取该节点的treedata对象，如果成功，就作为其children
            const children = getTreeData(nodeData, item.id,icon)
            if (children && children.length > 0) {
                item.children = children
            }

            if(!icon)
                treeData.push(item)
            else
                treeData.push({
                    ...item,
                    slots:{icon:icon}
                })
        }
    })
    return treeData // 每次递归会返回当前的子列表
}

export default {
    getCurrentTime: getCurrentTime,
    getSecondTimes: getSecondTimes,
    isMobileNumber: isMobileNumber,
    boMobileNumber: boMobileNumber,
    isValidatePass: isValidatePass,
    isValidateSMSCode: isValidateSMSCode,
    formatMsgTime: formatMsgTime,
    trim: trim,
    findElement: findElement,
    fuzzyStr: fuzzyStr,
    unFuzzyStr: unFuzzyStr,
    getStartDateOfYear: getStartDateOfYear,
    getEndDateOfYear: getEndDateOfYear,
    chunk: chunk,
    clearTxt: clearTxt,
    getBoSearchEnd: getBoSearchEnd,
    getQueryVariable: getQueryVariable,
    addDate: addDate,
    formatSeconds: formatSeconds,
    includeNumber: includeNumber,
    getReleaseYearList: getReleaseYearList,
    getToday: getToday,
    keepLastIndex: keepLastIndex,
    isPositiveInteger: isPositiveInteger,
    isPositiveInteger0To100: isPositiveInteger0To100,
    formatMinutes: formatMinutes,
    sortAnswer: sortAnswer,
    convertAnswer: convertAnswer,
    getStrCount: getStrCount,
    getFileType: getFileType,
    formatMinutesEx: formatMinutesEx,
    getDateDiffMinutes: getDateDiffMinutes,
    convertAnswer2: convertAnswer2,
    clearEmoji: clearEmoji,
    clearBackSlash: clearBackSlash,
    deleteHTMLTag: deleteHTMLTag,
    buildUnitPath,
    getTreeData:getTreeData
}

<template>
    <a-modal v-model="practiceModal" :footer="null" @cancel="onModalClose" :width="680" :bodyStyle="{'max-height':'610px','overflow':'auto'}" centered>
        <template slot="title">
            <a-menu mode="horizontal" :selectedKeys="[activeMenuIndex]" @click="onMessageClick">
                <a-menu-item key="0"> 智能刷题训练 </a-menu-item>
                <a-menu-item key="1"> 智能对话训练 </a-menu-item>
            </a-menu>
        </template>
        <div class="huiwen-container">
            <template v-if="practices.length > 0">
                <a-card v-for="(item, index) in practices" hoverable style="margin-bottom: 10px" @click="toPractice(index)">
                    <a-card-meta>
                        <template slot="title">
                            <span class="huiwen-text-bold huiwen-text-black huiwen-text-small-title">{{$consts.biz.PRACTICE_TYPE[item.mode].name}}</span>
                        </template>
                        <template slot="description">
                            <span class="huiwen-text-small-title huiwen-text-gary-light">{{item.start_time}}</span>
                            <span v-if="item.end_time" class="huiwen-margin-left-5 huiwen-text-small-title huiwen-text-gary-light"> ~ {{item.end_time}}</span>
                            <span class="huiwen-right huiwen-text-gary-light huiwen-text-small-title">评分：{{item.score}}</span>
                        </template>
                    </a-card-meta>
                </a-card>
            </template>
            <template v-else>
                <a-empty style="padding: 100px 0"></a-empty>
            </template>
            <cmy-page :page_now="practice_pagination.page" :bo_end="practice_pagination.end" @next="queryMyPractices(practice_pagination.page + 1)" @previous="queryMyPractices(practice_pagination.page - 1)"></cmy-page>
        </div>
    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core";
    import dateUtils from '@/utils/dateUtils.js'
    import {mapActions, mapGetters} from 'vuex'

    import CmyPage from '@/components/cmy-page/cmy-page.vue'
    export default {
        name: "my-practice",
        data() {
            return {
                practiceModal: false,
                activeMenuIndex: '0',

                practices: [],
                practice_pagination: {
                   page: 1,
                   limit: 5,
                   end: true
                }
            }
        },
        components: {
            CmyPage
        },
        computed: {
            ...mapGetters([
                'staffId',
            ])
        },
        watch: {
            'visible': function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.queryMyPractices()
                }
                this.practiceModal = newVal
            },
        },
        props: {
            visible: Boolean
        },
        methods: {
            onModalClose() {
                const that = this
                that.$emit('close')
            },
            onMessageClick({ key }) {
                const that = this
                that.activeMenuIndex = key
                that.queryMyPractices()
            },
            queryMyPractices(page = 1) {
                const that = this
                return new Promise((resolve,reject) => {
                    doQuery({
                        query_code: 'CCAIH0',
                        param_int1: parseInt(that.activeMenuIndex),
                        param_str1: '1970-01-01',
                        param_str2: dateUtils.getToday(),
                        page: page,
                        limit: that.practice_pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data ) {
                            that.practices = res.data
                            that.practice_pagination.page = page
                            that.practice_pagination.end = res.data.length < that.practice_pagination.limit
                            resolve()
                        }
                    })
                })
            },
            toPractice(index) {
                const that = this
                that.onModalClose()
                if(that.practices[index].mode == '0') {
                    if(that.$route.name == 'StudentBrushQuestion') {
                        that.$router.replace('/student/brushQuestion?session_id='+that.practices[index].session_id)
                    }else {
                        that.$router.push('/student/brushQuestion?session_id='+that.practices[index].session_id)
                    }
                }
                if(that.practices[index].mode == '1') {
                    if(that.$route.name == 'StudentTextPractice') {
                        that.$router.replace('/student/textPractice?session_id='+that.practices[index].session_id)
                    }else {
                        that.$router.push('/student/textPractice?session_id='+that.practices[index].session_id)
                    }
                }

            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-modal-header {
        padding: 6px 0px;
        border-bottom: none;
    }
</style>
